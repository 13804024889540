import React, { useEffect, useState } from 'react';
import { Autocomplete, ClickAwayListener, Tooltip, TooltipProps, tooltipClasses, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers-pro';
import { styled } from '@mui/material/styles';
import { CustomDatepickerProps } from './CustomDatepicker.props';
import { SizeProps } from '../../../@types';
import { formatDateOnly } from '../../../utils/common';
import { AUTOCOMPLET_DROPDOWN_ITEMS, DAYS_OF_WEEK, MONTHS_OF_YEAR, RAW_DATE_RANGES } from '../../../constants/attendanceReport';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    marginTop: 100
  },
}));

export const CustomDatepicker: React.FC<CustomDatepickerProps> = ({
  name,
  setDate,
  dateValue
}) => {
  const [value, setValue] = useState(AUTOCOMPLET_DROPDOWN_ITEMS);
  const [customParam, setCustomParam] = useState<string>();
  const [dateRanges, setDateRanges] = useState(RAW_DATE_RANGES);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openAutocomplete, setOpenAutocomplete] = useState(false);

  useEffect(() => {
    if (customParam) {
      // split input word by white space
      const inputTextArray = customParam?.split(' ');
      // extract only number if exists
      const number = inputTextArray.find((inputText: string) => /^\d+$/.test(inputText) === true);

      // check whether day name typed and if exist get day name as id
      const dayId = inputTextArray.find((inputText: string) => DAYS_OF_WEEK.includes(inputText.toLowerCase()));
      // convert first letter to uppercase before use as a label
      const dayName = dayId && (dayId.charAt(0).toUpperCase() + dayId.slice(1).toLowerCase());

      // check whether month name typed and if exist get month name as id
      const monthId = inputTextArray.find((inputText: string) => MONTHS_OF_YEAR.includes(inputText.toLowerCase()));
      // convert first letter to uppercase before use as a label
      const monthName = monthId && (monthId.charAt(0).toUpperCase() + monthId.slice(1).toLowerCase());

      // if number is a integer, merge below rows
      Number.isInteger(Number(number)) && 
      setDateRanges([
        ...dateRanges, 
        ...[
          { label: `${number} days ago`, value: `${number}-days-ago` },
          { label: `${number} weeks ago`, value: `${number}-weeks-ago` },
          { label: `${number} fortnights ago`, value: `${number}-fortnights-ago` },
          { label: `${number} months ago`, value: `${number}-months-ago` },
          { label: `${number} years ago`, value: `${number}-years-ago` },
          { label: `first day ${number} months ago`, value: `first-day-${number}-months-ago` },
          { label: `first day ${number} years ago`, value: `first-day-${number}-years-ago` },
          { label: `last day ${number} months ago`, value: `last-day-${number}-months-ago` },
          { label: `last day ${number} years ago`, value: `last-day-${number}-years-ago` }
        ],
      ]);

      // if day name exist, merge below rows
      dayName && 
      setDateRanges([
        ...dateRanges, 
        ...[
          { label: `${dayName} this week`, value: `${dayId.toLowerCase()}-this-week` },
          { label: `${dayName} last week`, value: `${dayId.toLowerCase()}-last-week` },
        ],
      ]);

      // if day name and integer given, merge below rows
      dayName && Number.isInteger(Number(number)) &&
      setDateRanges([
        ...dateRanges, 
        ...[
          { label: `${dayName} ${number} weeks ago`, value: `${dayId.toLowerCase()}-${number}-weeks-ago` },
        ],
      ]);

      // if month name exist, merge below rows
      monthName && 
      setDateRanges([
        ...dateRanges, 
        ...[
          { label: `first day of ${monthName} this year`, value: `first-day-of-${monthId.toLowerCase()}-this-year` },
          { label: `last day of ${monthName} last year`, value: `last-day-of-${monthId.toLowerCase()}-last-year` },
        ],
      ]);

      // if month name and integer given, merge below rows
      monthName && Number.isInteger(Number(number)) &&
      setDateRanges([
        ...dateRanges, 
        ...[
          { label: `first day of ${monthName} ${number} years ago`, value: `first-day-of-${monthId.toLowerCase()}-${number}-years-ago` },
        ],
      ]);

      // if month name and integer given and integer has 4 exact digits, merge below rows
      monthName && Number.isInteger(Number(number)) && number?.length === 4 &&
      setDateRanges([
        ...dateRanges, 
        ...[
          { label: `first day of ${monthName} ${number}`, value: `first-day-of-${monthId.toLowerCase()}-${number}` },
          { label: `last day of ${monthName} ${number}`, value: `last-day-of-${monthId.toLowerCase()}-${number}` },
        ],
      ]);
    }
  }, [customParam]);

  useEffect(() => {
    dateValue && setValue({
      label: dateRanges.find((dateRange) => dateRange.value === dateValue)?.label || dateValue,
      value: dateValue
    });
  }, [dateValue]);

  const handleCloseAll = () => {
    setOpenTooltip(false);
    setOpenAutocomplete(false);
  };

  return (
    <ClickAwayListener onClickAway={handleCloseAll}>
      <div>
        <StyledTooltip
          placement={'right'}
          onClose={() => setOpenTooltip(false)}
          open={openTooltip}
          disableFocusListener
          disableHoverListener
          title={
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDatePicker
                onAccept={() => {
                  value && setDate && setDate(value.value);
                  handleCloseAll;
                }}
                onChange={(event: any) => {
                  !openAutocomplete && setOpenAutocomplete(true);
                  setValue({
                    label: formatDateOnly(event?.toString()),
                    value: formatDateOnly(event?.toString())
                  });
                }}
                onViewChange={() => !openAutocomplete && setOpenAutocomplete(true)}
                onMonthChange={() => !openAutocomplete && setOpenAutocomplete(true)}
                onClose={handleCloseAll}
              />
            </LocalizationProvider>
          }
        >
          <Autocomplete
            freeSolo
            value={value}
            open={openAutocomplete}
            onOpen={() => setOpenAutocomplete(true)}
            onClose={() => setOpenAutocomplete(false)}
            id="combo-box-demo"
            options={[...new Map(dateRanges.map((dateRange) => [dateRange.value, dateRange])).values()]}
            size={SizeProps.Small}
            sx={{ width: 300 }}
            renderInput={(params) => 
              <TextField 
                {...params} 
                label={name}
                onClick={() => {
                  setOpenAutocomplete(true);
                  setOpenTooltip(true);
                }}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            }
            onChange={(obj: any, value: any) => {
              setDate && setDate(value?.value || '');
              setCustomParam(value?.label);
              handleCloseAll();
            }}
          />
        </StyledTooltip>
      </div>
    </ClickAwayListener>
  );
};