import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { SearchCode } from '../@types/part.type';
import { ActionType, AllocatedService, BillingType, CheckInObject, CustomNodeAttributeType, InputCategoryProps, Invoice, LinkObject, ProcessType, ServiceCommonType, ServiceObject, ServiceStatusResponse, ServiceType } from '../@types/service.type';
import { DropdownItem } from '../components/atoms/SelectMenu';
import { removeEmptyObjects } from '../configs/common';
import { PropertyType, ServiceAttributeName, ServiceAttributeType, ServiceCreationRuleTypes, ServiceStatusTypes, LogTypeNames, NodeTypeCode, ServiceStatusNames } from '../configs/enums';
import { ANY_ITEM_ARRAY, ANY_KEYWORD, EMPTY_FIELD_MESSAGE } from '../constants/common';
import { DefaultClientSelectorUndefinedText, columnSelectionItems } from '../constants/service.constant';
import { CHARACTER_CUSTOM_LENGTH_MESSAGE, CONTAIN_ONLY_VALID_CHARACTERS_MESSAGE } from '../constants/user.constant';
import { getCurrentTimestamp, getTimestamp, isDropDownEmpty, isEmptyString } from './common';
import { getFileMimeTypeByExtension, getContentDispositionTypeByExtension } from '../utils/file-utils';
import { FormObject, FormFieldType, ParameterNoUIData, FormContainerItem, ModifyStructureFormModifyStructureType } from '../@types/form-builder.type';
import { ActionPermissions, CustomPermission, ServiceListColumnItemTitle, StructureAttribute } from '../@types';
import { ColumnSelectionItem } from '../components/templates/ColumnSelection';
import { ServiceNodeAllocation } from '../@types/allocation.type';
import { isUserHasPermission } from '../configs/permissions';

export const conatainValidCharacters = (value: string, type: string) => {
  if(type === InputCategoryProps.Name) {
    if (/^[ A-Za-z0-9_/\\,-]*$/.test(value)) {  
      return false;
    } else {
      return true;
    }
  } else if (type === InputCategoryProps.ShortName) {
    if (/^[A-Z0-9_-]+$/i.test(value)) {
      return false;
    } else {
      return true;
    }
  } else if (type === InputCategoryProps.Code) {
    if (/^[A-Z0-9]+$/i.test(value)) {
      return false;
    } else {
      return true;
    }
  }
};

export const nameValidation = (value: string, type: string, text: string, length: number) => {
  if (isEmptyString(value)) {
    return text + EMPTY_FIELD_MESSAGE;
  } else if (conatainValidCharacters(value, type)) {
    return CONTAIN_ONLY_VALID_CHARACTERS_MESSAGE + value;
  } else if (value.length > length) {
    return CHARACTER_CUSTOM_LENGTH_MESSAGE + length + ' characters';
  } else {
    return '';
  }
};

export const isClientEmpty = (text: string) => {
  if (text == '' || text == DefaultClientSelectorUndefinedText) {
    return true;
  } else {
    return false;
  }
};

export const isInputFieldEmpty = (value: string) => {
  if (isEmptyString(value)) {
    return true;
  } else {
    return false;
  }
};

// To get True or false
export const LengthValidation = (value: string, length: number) => {
  return (value.length > length);
};

export const getServiceAttributes = (data: ServiceObject) => {
  const serviceAttributes: any[] = [];

  if (data.referenceNo1) {
    serviceAttributes.push({
      value: data.referenceNo1,
      name: ServiceAttributeName.Reference1,
      attributeTypeCode: ServiceAttributeType.Reference1
    });
  }
  if (data.referenceNo2) {
    serviceAttributes.push({
      value: data.referenceNo2,
      name: ServiceAttributeName.Reference2,
      attributeTypeCode: ServiceAttributeType.Reference2
    });
  }
  if (data.referenceNo3) {
    serviceAttributes.push({
      value: data.referenceNo3,
      name: ServiceAttributeName.Reference3,
      attributeTypeCode: ServiceAttributeType.Reference3
    });
  }
  if (data.userDepotAllocation) {
    serviceAttributes.push({
      value: data.userDepotAllocation,
      name: ServiceAttributeName.UserDepotAllocation,
      attributeTypeCode: ServiceAttributeType.UserDepotAllocation
    });
  }
  if (data.reportedSerialNumber) {
    serviceAttributes.push({
      value: data.reportedSerialNumberPartId,
      name: ServiceAttributeName.ReportedSerialNumber,
      attributeTypeCode: ServiceAttributeType.ReportedSerialNumber
    });
  }
  if (data.reportedModel) {
    serviceAttributes.push({
      // TODO: I think you need to change this to reportedModelId @kalana
      value: data.reportedModel,
      name: ServiceAttributeName.ReportedModel,
      attributeTypeCode: ServiceAttributeType.ReportedModel
    });
  }
  if (data.reportedFaultAndDescription) {
    serviceAttributes.push({
      // TODO: I think you need to change this to reportedFaultAndDescriptionId @kalana
      value: data.reportedFaultAndDescription,
      name: ServiceAttributeName.ReportedFaultAndDescription,
      attributeTypeCode: ServiceAttributeType.ReportedFaultAndDescription
    });
  }
  if (data.elementForDisplaying) {
    serviceAttributes.push({
      value: data.elementForDisplaying,
      name: ServiceAttributeName.ElementOfDisplaying,
      attributeTypeCode: ServiceAttributeType.ElementOfDisplaying
    });
  }

  return serviceAttributes;
};

// TODO: I have created a new getServiceAttributes here with correct logics for interceptor forms.
export const getServiceAttributesForServiceCreationForm = (data: ServiceObject) => {
  const serviceAttributes: any[] = [];

  if (data.referenceNo1) {
    serviceAttributes.push({
      value: data.referenceNo1,
      name: ServiceAttributeName.Reference1,
      attributeTypeCode: ServiceAttributeType.Reference1
    });
  }
  if (data.referenceNo2) {
    serviceAttributes.push({
      value: data.referenceNo2,
      name: ServiceAttributeName.Reference2,
      attributeTypeCode: ServiceAttributeType.Reference2
    });
  }
  if (data.referenceNo3) {
    serviceAttributes.push({
      value: data.referenceNo3,
      name: ServiceAttributeName.Reference3,
      attributeTypeCode: ServiceAttributeType.Reference3
    });
  }
  if (data.userDepotAllocation) {
    serviceAttributes.push({
      value: data.userDepotAllocation,
      name: ServiceAttributeName.UserDepotAllocation,
      attributeTypeCode: ServiceAttributeType.UserDepotAllocation
    });
  }
  if (data.reportedSerialNumber) {
    serviceAttributes.push({
      value: data.reportedSerialNumberPartId,
      name: ServiceAttributeName.ReportedSerialNumber,
      attributeTypeCode: ServiceAttributeType.ReportedSerialNumber
    });
  }
  if (data.reportedModelId) {
    serviceAttributes.push({
      value: data.reportedModelId,
      name: ServiceAttributeName.ReportedModel,
      attributeTypeCode: ServiceAttributeType.ReportedModel
    });
  }
  if (data.reportedFaultAndDescriptionId) {
    serviceAttributes.push({
      value: data.reportedFaultAndDescriptionId,
      name: ServiceAttributeName.ReportedFaultAndDescription,
      attributeTypeCode: ServiceAttributeType.ReportedFaultAndDescription
    });
  }
  if (data.elementForDisplaying) {
    serviceAttributes.push({
      value: data.elementForDisplaying,
      name: ServiceAttributeName.ElementOfDisplaying,
      attributeTypeCode: ServiceAttributeType.ElementOfDisplaying
    });
  }

  return serviceAttributes;
};

export const isRuleExist = (rules: any, propertyName: string) => {
  let isExist = false;
  
  if (rules && rules.length) {
    isExist = rules.some((rule: string) => rule === propertyName);
  }

  return isExist;
};

export const checkForSelectedRules = (data: ServiceObject): boolean => {
  const selectedRules: Array<any> = data.selectedServiceCreationTypeValue || [];
  return (isRuleExist(selectedRules, ServiceCreationRuleTypes.Reference1) && isEmptyString(data.referenceNo1)) ||
  (isRuleExist(selectedRules, ServiceCreationRuleTypes.Reference2) && isEmptyString(data.referenceNo2)) ||
  (isRuleExist(selectedRules, ServiceCreationRuleTypes.Reference3) && isEmptyString(data.referenceNo3)) ||
  (isRuleExist(selectedRules, ServiceCreationRuleTypes.FaultAndDescription) && isEmptyString(data.reportedFaultAndDescriptionId)) ||
  (isRuleExist(selectedRules, ServiceCreationRuleTypes.Model) && isEmptyString(data.reportedModelId)) ||
  (isRuleExist(selectedRules, ServiceCreationRuleTypes.SerialNumber) && isEmptyString(data.reportedSerialNumber)) ||
  (isRuleExist(selectedRules, ServiceCreationRuleTypes.Notes) && isEmptyString(data.notes));
};

export const isHaveError = (data: ServiceObject, selectedParentHierarchy: string, selectedRules: Array<any>) => {
  return isDropDownEmpty(data.serviceTypeCode) ||
    isDropDownEmpty(data.actionTypeCode) ||
    isDropDownEmpty(data.processTypeCode) ||
    isDropDownEmpty(data.priorityCode) ||
    isClientEmpty(selectedParentHierarchy) ||
    (isRuleExist(selectedRules, ServiceCreationRuleTypes.Reference1) && isEmptyString(data.referenceNo1)) ||
    (isRuleExist(selectedRules, ServiceCreationRuleTypes.Reference2) && isEmptyString(data.referenceNo2)) ||
    (isRuleExist(selectedRules, ServiceCreationRuleTypes.Reference3) && isEmptyString(data.referenceNo3)) ||
    (isRuleExist(selectedRules, ServiceCreationRuleTypes.FaultAndDescription) && isDropDownEmpty(data.reportedFaultAndDescription)) ||
    (isRuleExist(selectedRules, ServiceCreationRuleTypes.Model) && isDropDownEmpty(data.reportedModel)) ||
    (isRuleExist(selectedRules, ServiceCreationRuleTypes.SerialNumber) && isEmptyString(data.reportedSerialNumber)) ||
    (isRuleExist(selectedRules, ServiceCreationRuleTypes.Notes) && isEmptyString(data.notes));
};

export const serviceTypesData = (serviceTypes: ServiceType[]) => {
  const serviceTypeList = [
    serviceTypes?.map((serviceType: ServiceType) => {
      return {
        value: serviceType.code,
        label: serviceType.name,
        disabled: !serviceType.isActive
      };
    })
  ];
  return serviceTypeList[0];
};

export const actionTypesData = (actionTypes: ActionType[]) => {
  const actionTypeList = [
    actionTypes?.map((actionType: ActionType) => {
      return {
        value: actionType.code,
        label: actionType.name,
        disabled: false
      };
    })
  ];
  return actionTypeList[0];
};

export const processTypesData = (processTypes: ProcessType[]) => {
  const processTypeList = [
    processTypes?.map((processType: ProcessType) => {
      return {
        value: processType.code,
        label: processType.name,
        disabled: false
      };
    })
  ];
  return processTypeList[0];
};

// Use this for retrieve service types
export const serviceTypes = (serviceTypes: ServiceType[]) => {
  const serviceTypeList = serviceTypesData(serviceTypes);
  return serviceTypeList;
};

// Use this for retrieve action types
export const actionTypes = (actionTypes: any) => {
  const actionTypeList = actionTypesData(actionTypes);
  return actionTypeList;
};

// Use this for retrieve process types
export const processTypes = (processTypes: any) => {
  const processTypeList = processTypesData(processTypes);
  return processTypeList;
};

// Use this for retrieve service types data with any wildcard.
export const serviceTypesWithAnyWildcard = (serviceTypes: any) => {
  const defaultServiceType = [{ value: 'any', label: 'Any', disabled: false }];
  const serviceTypeList = serviceTypesData(serviceTypes);
  return defaultServiceType.concat(serviceTypeList);
};

// Use this for retrieve action types data with any wildcard.
export const actionTypesWithAnyWildcard = (actionTypes: any) => {
  const defaultActionType = [{ value: 'any', label: 'Any', disabled: false }];
  const actionTypeList = actionTypesData(actionTypes);
  return defaultActionType.concat(actionTypeList);
};

// Use this for retrieve process types data with any wildcard.
export const processTypesWithAnyWildcard = (processTypes: any) => {
  const defaultProcessType = [{ value: 'any', label: 'Any', disabled: false }];
  const processTypeList = processTypesData(processTypes);
  return defaultProcessType.concat(processTypeList);
};

export const prioritiesWithAnyWildcard = (priorities: Array<DropdownItem>, selectedValues?: string[]) => {
  if (selectedValues && selectedValues.some((selectedValue: string) => selectedValue === ANY_KEYWORD)) {
    return ANY_ITEM_ARRAY;
  }

  return ANY_ITEM_ARRAY.concat(priorities);
};

export const priorityTypes = (attributeTypes: any) => {
  const priorityTypeList = [
    attributeTypes?.map((attributeType: any) => {
      if (attributeType?.propertyTypeCode === PropertyType.PriorityAndService) {
        return {
          value: attributeType?.id,
          label: attributeType?.value?.shortName,
          color: attributeType?.value?.color,
          description: attributeType?.value?.description,
          disabled: !attributeType?.value?.isActive
        };
      }
      return {};
    })
  ];
  return removeEmptyObjects(priorityTypeList[0]);
};

// Use this to retrieve service statuses data with any wildcard.
export const availableStatusesWithAnyWildcard = (availableStatuses: DropdownItem[]) => {
  const defaultProcessType: DropdownItem[] = [{ value: 'any', label: 'Any', disabled: false }];
  return defaultProcessType.concat(availableStatuses);
};

export const priorityTypesForSLA = (attributeTypes: any) => {  
  let priorityData: any[] = [];      
  const anyWildCard = { value: 'any', label: 'Any', serviceTypes: ['any'] };
  priorityData.push(anyWildCard);
  const priorityTypeList = attributeTypes?.reduce((accumulator: any[], currentValue: any) => {
    if (currentValue.propertyTypeCode === PropertyType.PriorityAndService && currentValue.value?.isActive) {
      const data = {
        value: currentValue?.id.toString(),
        label: currentValue?.value?.shortName,
        serviceTypes: currentValue?.value?.selectedServiceTypeValue
      };
      return [...accumulator, data];
    }
    return accumulator;
  }, []);
  priorityData = [ ...priorityData, ...priorityTypeList ];
  return priorityData;
};

export const reportedModelTypes = (reportedModelTypes: any) => {
  const reportedModelTypeList = [
    reportedModelTypes?.map((reportedModelType: any) => {
      if (reportedModelType?.propertyTypeCode === 'models') {
        return {
          value: reportedModelType?.id,
          label: reportedModelType?.value?.clientModelName,
          disabled: !reportedModelType?.value?.enabled
        };
      }
      return {};
    })
  ];
  return removeEmptyObjects(reportedModelTypeList[0]);
};

export const reportedFaultTypes = (reportedFaultTypes: Array<StructureAttribute>) => { 
  const reportedFaultTypeList: DropdownItem[] = [];
  const isSubFaultExist = reportedFaultTypes?.some((reportedFaultType: StructureAttribute) => reportedFaultType.propertyTypeCode === PropertyType.FaultCode);

  // if sub-faults exists
  if (isSubFaultExist) {
    reportedFaultTypes?.map((reportedMainFault: StructureAttribute) => {
      const mainFaultwithSubFaults = reportedMainFault?.propertyTypeCode === PropertyType.FaultCategory && reportedFaultTypes?.filter(
        (reportedFault: StructureAttribute) => reportedFault.propertyTypeCode === PropertyType.FaultCode && reportedFault.value.categoryId === reportedMainFault?.id
      );

      // if sub-faults exists
      if (mainFaultwithSubFaults && mainFaultwithSubFaults.length > 0) {
        mainFaultwithSubFaults?.map((reportedSubFault: StructureAttribute) => {
          if (reportedMainFault?.value?.faults?.isActive && reportedSubFault?.value?.isActive) {
            reportedFaultTypeList.push({
              value: reportedSubFault?.id.toString(),
              label: reportedMainFault?.value?.faults?.name + ' - ' + reportedSubFault?.value?.name,
            });
          }
        });
      } 
      else { // if main-faults exists
        if (reportedMainFault?.value?.faults?.isActive) {
          reportedFaultTypeList.push({
            value: reportedMainFault?.id.toString(),
            label: reportedMainFault?.value?.faults?.name,
          });
        }
      }
    });
  }

  return removeEmptyObjects(reportedFaultTypeList.sort((firstElement, secondElement) => firstElement.label.localeCompare(secondElement.label)));
};

export const statusOptionItems = (statusOptions: Array<ServiceType>, serviceOpen?: boolean, isCancelOrReopen?: string) => {
  return statusOptions?.filter((status) => !isCancelOrReopen && serviceOpen ? status.code !== ServiceStatusTypes.Cancelled && status.code !== ServiceStatusTypes.Futile : true).map((obj) => ({
    label: obj.name,
    value: obj.code,
    disabled: !obj.isActive
  })) || [];
};

export const filterInteractionData = (interactions: Array<LinkObject>, serviceId: number) => {
  return interactions.filter((interaction: LinkObject) => interaction.serviceId !== serviceId);
};

export const processBulkCreateData = (
  allocatedServices: Array<AllocatedService>,
  notCheckedInService: Array<AllocatedService>,
  checkedInService: Array<AllocatedService>,
  travelStart: string,
  data: ServiceObject,
  noteText: string
) => {
  const filteredCheckInServices = allocatedServices.filter((alocated: AllocatedService) => alocated?.isUserCheckedIn);
  const servicesMerge = filteredCheckInServices.filter(filteredCheckInService => !notCheckedInService.some(notChecked => filteredCheckInService.id === notChecked.id));
  const finalServiceResults = servicesMerge.concat(checkedInService);
  const results: Array<CheckInObject> = [];

  finalServiceResults.map((finalServiceResult: AllocatedService) => {
    const result = {
      serviceId: finalServiceResult.serviceId,
      checkInTime: getCurrentTimestamp(),
      travelStartTime: getTimestamp(travelStart),
      locationNodeId: data.subLocationNodeId ?? data.mainLocationNodeId ?? 0,
      notes: noteText
    };
    results.push(result);
  });

  return results;
};

export const getCheckedInData = (checkins: Array<ServiceStatusResponse>) => {
  return checkins.find((checkinData) => checkinData.id === Math.max(...checkins.map(checkin => checkin.id)));
};

export const getHasCodeForNodeSearch = (name: string) => {
  return name.toUpperCase().startsWith(SearchCode.AM_LID);
};

export const generateImportURL= (importSessionID: number) => {
  return `/services?importSessionId=${importSessionID}`;
};

export const getFileAttachmentURLData= (inputString: string, logTypeName: string) => {

  // Regular expression pattern to get the file key and file name
  const pattern = /<span[^>]*?fileKey=["'](.*?)["'][^>]*?>(.*?)<\/span>/i;

  // Old pattern is kept so that old image preview links will also be opened. But the re-anchoring issue prevails in old pattern.
  const oldPattern = /<a[^>]*?fileKey=["'](.*?)["'][^>]*?>(.*?)<\/a>/i;

  const matches = pattern.exec(inputString) || oldPattern.exec(inputString);

  if (matches && matches.length >= 3) {
    const fileKey = matches[1];
    const fileName = matches[2];

    const extension = logTypeName === LogTypeNames.InteractionEvent ? fileKey.split('.')[1] : fileName.split('.')[1];

    const urlData = {
      key: fileKey,
      mimeType: getFileMimeTypeByExtension(extension),
      contentDispositionType: getContentDispositionTypeByExtension(extension),
      fileName: fileName.split('.')[0].trim()
    };

    return(urlData);
  } 
};

export const adjustTime = (time: string, minutes: number): string => {
  if (!time) return time;

  const [hours, currentMinutes] = time.split(':').map(Number);
  const totalMinutes = hours * 60 + currentMinutes;
  const newTotalMinutes = totalMinutes + minutes;

  const nonNegativeTotalMinutes = Math.max(0, newTotalMinutes);

  const newHours = Math.floor(nonNegativeTotalMinutes / 60);
  const newMinutes = nonNegativeTotalMinutes % 60;

  return `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;
};

// This function checks whether the intercepted forms contain only a set parameter no UI form. If so, 
// auto submission will happen for that intercepted form
export const checkSetParameterNoUIFormAvailability= (forms?: FormObject[]) => {
  return forms?.every((form) =>
    form.containers.every((container) => 
      container.fields.every((field) => 
        field.title === FormFieldType.SetParameterNoUI && field.value
      )
    )
  );
};

export const getSetParameterNoUIFormSelectedValue= (preSelectedData: ParameterNoUIData) => {
  if (preSelectedData?.billableType) {
    return `Billable-${preSelectedData?.billableType}`;
  } else if (preSelectedData?.slaData) {
    return `SLA-${preSelectedData?.slaData?.option} ${preSelectedData?.slaData?.type || ''} ${preSelectedData?.slaData?.reasonName || ''} ${preSelectedData?.slaData?.notes || ''}`;
  } else if (preSelectedData?.partData) {
    return `Part-${preSelectedData?.partData?.attribute} '${preSelectedData?.partData?.condition || preSelectedData?.partData?.fleetTag || ''}' ${preSelectedData?.partData?.movementType || ''}`;
  } else {
    return '';
  }
};

export const getServiceLocationAccordionAddress = (addressData: any) => { // Address data type changes
  return (addressData?.line1 || '')+' '+(addressData?.line2 || '')+' '+(addressData?.suburb || '')+' '+(addressData?.city || '')+' '+(addressData?.stateName || '')+' '+(addressData?.postCode || '');
};

export const getInitialPriority = (nodeAttributes: Array<CustomNodeAttributeType>) => {
  const priorities = nodeAttributes.filter((attribute) => attribute.propertyTypeCode === PropertyType.PriorityAndService && attribute.value.isActive);
  if (priorities.length === 1) {
    return priorities[0].id.toString();
  }
  return '';
};

// This function checks whether the intercepted forms contain only the Get Geo-Location form. If so, 
// auto submission will happen for that intercepted form
export const checkGetGeoLocationFormAvailability= (forms?: FormObject[]) => {
  return forms?.every((form) =>
    form.containers.every((container) => 
      container.fields.every((field) => 
        field.title === FormFieldType.GetGeoLocation
      )
    )
  );
};

export const setFormPaddingByGeoLocationFormAvailability = (containerData: FormContainerItem) => {
  return containerData.fields.length === 1 && containerData.fields[0].type === FormFieldType.GetGeoLocation ? '0px' : '20px';
};

export const getServicesListFilteredColumns = (reportColumnSelectionItems: Array<ColumnSelectionItem>, columns: GridColDef[]) => {
  if (
    (reportColumnSelectionItems.length > 2 && !reportColumnSelectionItems[2].value.find((column) => column.field === 'serviceAddress')?.value) || 
    (!reportColumnSelectionItems.length && !columnSelectionItems[2].value.find((column) => column.field === 'serviceAddress')?.value)
  ) {
    columns = columns.filter((column) => column.field !== 'line1' && column.field !== 'line2' && column.field !== 'suburb' && column.field !== 'city' && column.field !== 'stateName' && column.field !== 'postCode' && column.field !== 'countryName');
  } 
  if (
    (reportColumnSelectionItems.length > 2 && !reportColumnSelectionItems[2].value.find((column) => column.field === 'nodeReferences')?.value) || 
    (!reportColumnSelectionItems.length && !columnSelectionItems[2].value.find((column) => column.field === 'nodeReferences')?.value)
  ) {
    columns = columns.filter((column) => column.field !== 'nodeReference1' && column.field !== 'nodeReference2' && column.field !== 'nodeReference3');
  } 
  if (
    (reportColumnSelectionItems.length > 5 && !reportColumnSelectionItems[5].value.find((column) => column.field === 'partActions')?.value) || 
    (!reportColumnSelectionItems.length && !columnSelectionItems[5].value.find((column) => column.field === 'partActions')?.value)
  ) {
    columns = columns.filter((column) => column.field !== 'serial1sIn' && column.field !== 'serialisedPartTypesIn' && column.field !== 'serial1sOut' && column.field !== 'serialisedPartTypesOut' && column.field !== 'nonSerialisedPartTypesIn');
  }
  if (
    (reportColumnSelectionItems.length > 7 && !reportColumnSelectionItems[7].value.find((column) => column.field === 'surveyColumns')?.value) || 
    (!reportColumnSelectionItems.length && !columnSelectionItems[7].value.find((column) => column.field === 'surveyColumns')?.value)
  ) {
    columns = columns.filter((column) => column.field !== 'interactionTextbox' && column.field !== 'interactionRichTextbox' && column.field !== 'interactionDropdown' && column.field !== 'interactionRadiobutton' && column.field !== 'interactionCheckbox' && column.field !== 'interactionGetParameter');
  }
  if (
    (reportColumnSelectionItems.length > 7 && !reportColumnSelectionItems[7].value.find((column) => column.field === 'partsPending')?.value) || 
    (!reportColumnSelectionItems.length && !columnSelectionItems[7].value.find((column) => column.field === 'partsPending')?.value)
  ) {
    columns = columns.filter((column) => column.field !== 'pendedParts' && column.field !== 'pendedNotes');
  }
  return columns;
};

export const formatServiceAttachmentsData = (attachmentData: {fileName: string; key: string}) => {
  let filesString = '';
  if (attachmentData) {
    filesString = filesString.concat(`<a href='#'>${attachmentData.fileName}</a>`);
  }
  return filesString;
};

export const getServiceAttachmentURLData = (attachmentData: {fileName: string; key: string}) => {
  if (attachmentData) {
    const extension = attachmentData.fileName.split('.')[1];
    const urlData = {
      key: attachmentData.key,
      mimeType: getFileMimeTypeByExtension(extension),
      contentDispositionType: getContentDispositionTypeByExtension(extension),
      fileName: attachmentData.fileName
    };
    return(urlData);
  }
};

export const generateServiceURL = (serviceId: number) => {
  return `/services/${serviceId}`;
};

export const getBillingTypesDropdownData = (billingTypes: BillingType[]) => {
  const dropdownData: DropdownItem[] = [];
  for (const billingType of billingTypes) {
    const dataObject = {
      label: billingType.name,
      value: billingType.code
    };
    dropdownData.push(dataObject);
  }
  return dropdownData;
};

export const getInvoicesByType = (invoiceList: Invoice[], type: string) => {
  return invoiceList.filter((invoice) => invoice.invoiceType === type);
};

export const getServiceActionProcessTypesForMultiSelectFilters = (typesData: ServiceCommonType) => {
  const serviceTypesData = typesData.serviceTypes.filter((serviceType) => serviceType.isActive).map(listData => (
    { value: listData.name, label: listData.name }
  ));
  const actionTypesData = typesData.actionTypes.map(listData => (
    { value: listData.name, label: listData.name }
  ));
  const processTypesData = typesData.processTypes.map(listData => (
    { value: listData.name, label: listData.name }
  ));
  return { serviceTypesData, actionTypesData, processTypesData };
};

export const getAllocationNodeAccess = (permissions:CustomPermission[], allocationNode: ServiceNodeAllocation, parentNodeCheck?: boolean) => {
  const targetNode = parentNodeCheck ? allocationNode.parentNode : allocationNode.node;
  const isSibling = targetNode?.nodeTypeCode === NodeTypeCode.Sibling;

  const actionPermission = isSibling
    ? ActionPermissions.Configure_User_Edit_General
    : ActionPermissions.Structure_Edit_View_Structure_Location;
  const hasAccess = isUserHasPermission(actionPermission, permissions) && !!targetNode?.id;
  const url = isSibling ? `configure/users/${targetNode.code}` : `structure/${targetNode?.id}`;

  return { hasAccess, url };
};

export const setCellStylingInServiceActivityMonitor = (params: GridCellParams) => {
  if (params.field === 'newStatus' && params.value) {
    return 'new-status-styling';
  } else if (params.field === 'partsRequiredStatus' && params.value) {
    return 'parts-required-status-styling';
  } else if (params.field === 'escalatedStatus' && params.value) {
    return 'escalated-status-styling';
  } else if (params.field === 'rowHeading' && params.value) {
    return 'normal-styling';
  } 
  else return 'normal-activity-monitor-styling';
};

export const generateServicesFilterURL = (clientCode: string, serviceTypeName: string, serviceStatusGroup: string) => {
  return `/services?clientCode=${clientCode}&serviceTypeName=${serviceTypeName}&serviceStatusGroup=${serviceStatusGroup}`;
};

export const getServiceStatusNamesByStatusGroup = (statusGroup: string) => {
  switch (statusGroup) {
    case 'New': 
      return `${ServiceStatusNames.New}`;
    case 'Booking': 
      return `${ServiceStatusNames.ReadyForBooking},${ServiceStatusNames.CallbackRequired},${ServiceStatusNames.OnHold}`;
    case 'Field Ready': 
      return `${ServiceStatusNames.Assigned},${ServiceStatusNames.Scheduled},${ServiceStatusNames.Available},${ServiceStatusNames.PartsAvailable}`;
    case 'OTW': 
      return `${ServiceStatusNames.OnTheWay}`;
    case 'On Site': 
      return `${ServiceStatusNames.OnSite},${ServiceStatusNames.Exception}`;
    case 'Parts Required': 
      return `${ServiceStatusNames.PartsRequired}`;
    case 'Parts (Processed)': 
      return `${ServiceStatusNames.PartsInRepair},${ServiceStatusNames.PartsInOrder},${ServiceStatusNames.PartsInTransit}`;
    case 'Logistics': 
      return `${ServiceStatusNames.WithLogistics}`;
    case 'Dispatched': 
      return `${ServiceStatusNames.Dispatched},${ServiceStatusNames.WaitingReturn}`;
    case 'Escalated': 
      return `${ServiceStatusNames.Escalated}`;
    default:
      return '';
  }
};

export const getServiceActivityFilters = (clientCode: string, serviceTypeName: string, serviceStatusGroup: string) => {
  const filterModel = [
    {
      columnField: 'clientCode',
      operatorValue: 'equals',
      value: clientCode
    },
    {
      columnField: 'serviceTypeName',
      operatorValue: 'is',
      value: serviceTypeName
    },
    {
      columnField: 'serviceStatusName',
      operatorValue: 'isAnyOf',
      value: getServiceStatusNamesByStatusGroup(serviceStatusGroup).split(',')
    }
  ];
  return filterModel;
};

export const handleServiceListCoulumnPermissions = (columnSelectionItems: any, permissions: CustomPermission[]) => {
  let filteredColumnSelectionItems = columnSelectionItems;

  if (!isUserHasPermission(ActionPermissions.Service_Search_Columns_Service_details, permissions)) {
    filteredColumnSelectionItems = filteredColumnSelectionItems.filter((columnSelectionItem: any) => columnSelectionItem.title !== ServiceListColumnItemTitle.Service);
  }

  if (!isUserHasPermission(ActionPermissions.Service_Search_Columns_Statuse_Allocation, permissions)) {
    filteredColumnSelectionItems = filteredColumnSelectionItems.filter((columnSelectionItem: any) => columnSelectionItem.title !== ServiceListColumnItemTitle.StatuAndAllocation);
  }

  if (!isUserHasPermission(ActionPermissions.Service_Search_Columns_Structure, permissions)) {
    filteredColumnSelectionItems = filteredColumnSelectionItems.filter((columnSelectionItem: any) => columnSelectionItem.title !== ServiceListColumnItemTitle.Structure);
  }

  if (!isUserHasPermission(ActionPermissions.Service_Search_Columns_Part_Details, permissions)) {
    filteredColumnSelectionItems = filteredColumnSelectionItems.filter((columnSelectionItem: any) => columnSelectionItem.title !== ServiceListColumnItemTitle.ReportedPart);
  }

  if (!isUserHasPermission(ActionPermissions.Service_Search_Columns_Linked_Records, permissions)) {
    filteredColumnSelectionItems = filteredColumnSelectionItems.filter((columnSelectionItem: any) => columnSelectionItem.title !== ServiceListColumnItemTitle.LinkedRecords);
  }

  if (!isUserHasPermission(ActionPermissions.Service_Search_Columns_SLA, permissions)) {
    filteredColumnSelectionItems = filteredColumnSelectionItems.filter((columnSelectionItem: any) => columnSelectionItem.title !== ServiceListColumnItemTitle.SLA);
  }

  if (!isUserHasPermission(ActionPermissions.Service_Search_Columns_Progress_Data, permissions)) {
    filteredColumnSelectionItems = filteredColumnSelectionItems.filter((columnSelectionItem: any) => columnSelectionItem.title !== ServiceListColumnItemTitle.Progress);
  }

  if (!isUserHasPermission(ActionPermissions.Service_Search_Columns_Interactions, permissions)) {
    filteredColumnSelectionItems = filteredColumnSelectionItems.filter((columnSelectionItem: any) => columnSelectionItem.title !== ServiceListColumnItemTitle.Interactions);
  }

  if (!isUserHasPermission(ActionPermissions.Service_Search_Columns_Admin_And_Billing, permissions)) {
    filteredColumnSelectionItems = filteredColumnSelectionItems.filter((columnSelectionItem: any) => columnSelectionItem.title !== ServiceListColumnItemTitle.BillingAndAdmin);
  }

  return filteredColumnSelectionItems;
};

// Check for interaction forms with only modify structure toggle active interaction
export const checkModifyStructureToggleActiveFormAvailability= (forms?: FormObject[]) => {
  return forms?.every((form) =>
    form.containers.every((container) => 
      container.fields.every((field) => 
        field.type === FormFieldType.ModifyStructure && field.preSelectedData?.structureType === ModifyStructureFormModifyStructureType.ToggleActive
      )
    )
  );
};