import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  GridCellModes,
  GridCellParams,
  GridColDef,
  GridFeatureModeConstant,
  GridFilterModel,
  GridRenderCellParams,
  GridSelectionModel,
  GridSortModel,
  GridValueFormatterParams,
  GridValueGetterParams,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import { Interweave } from 'interweave';
import { LogoImageBox, ImageWrapper, Image, SwitchGrid, HeaderWrapper, GridContainer } from './ServiceList.styles';
import { ColumnSelectionItem } from '../../../components/templates/ColumnSelection';
import { CustomDataGrid } from '../../../components/layouts/CustomDataGrid';
import { ActionPermissions, CustomPermission, LabelValue, ListType, PropReportDataType, NewReportDataType, ReportResponse, OverwriteReportDataType, ColorProps, ScreenSize, CustomDensityOption } from '../../../@types';
import { checkListPagetFilterAvailability, formatDate, getDateFromTimestamp } from '../../../utils/common';
import { useGetActionTypes, useGetBulkServicesByIds, useGetProcessTypes, useGetServicePaginatedData, useUpdateBulkServiceList, useGetBillingInfo, useUpdateBillingInfo, useGetServiceBillingTypes, useGetServiceActionProcessTypes } from '../../../queries/service-query';
import { useGetServicesStatuses } from '../../../queries/service-status-query';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { SelectedServiceListDataType } from '../../../components/organisms/BulkServiceEditor';
import { BulkEditServiceDataType, ServiceBillingInfo, ServiceFilterMultiSelectType, ServiceListDataType } from '../../../@types/service.type';
import { SERVICE_ATTACHMENT_FILE_URL_DATA, SERVICE_BILLING_DATA, linkedFieldItems } from '../../../constants/service.constant';
import { isUserHasPermission } from '../../../configs/permissions';
import { Link } from '../../../components/atoms/Link';
import { InvoiceType, SavedReportTypeList } from '../../../configs/enums';
import { useAddNewReport, useGetDefaultReportByReportType, useGetReportByCode, useUpdateReportByCode } from '../../../queries/report-query';
import { Snackbar } from '../../../components/atoms/Snackbar';
import { generateReportURL } from '../../../utils/report';
import { columnSelectionItems } from '../../../constants/service.constant';
import { TagCell } from '../../../components/molecules/TagCell';
import { formatServiceAttachmentsData, getBillingTypesDropdownData, getInvoicesByType, getServiceActionProcessTypesForMultiSelectFilters, getServiceActivityFilters, getServiceAttachmentURLData, getServicesListFilteredColumns, handleServiceListCoulumnPermissions } from '../../../utils/services';
import { FileAttachmentURLType, FileMimeTypes } from '../../../@types/uploaded-files.type';
import { useGetUploadedFilePresignedUrlByKey } from '../../../queries/uploadedfiles-query';
import { downloadFile } from '../../../utils/file-utils';
import { Modal } from '../../../components/atoms/Modal';
import { Switch } from '../../../components/atoms/Switch';
import { FormControlLabel } from '../../../components/atoms/FormControlLabel';
import { MobileDataGridItem } from '../../../components/organisms/MobileDataGridItem';
import { MOBILE_DATA_GRID_COLUMN } from '../../../configs/ui-constants';
import { useWindowDimensions } from '../../../utils/resize-hook';
import { MobileDataGridItemEntryData } from '../../../components/molecules/MobileDataGridItemEntry';
import { PLATFORM_NAME } from '../../../configs/common';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { EditField } from '../../../components/molecules/EditField';
import { SelectMenu } from '../../../components/atoms/SelectMenu';
import { InvoiceList } from '../../../components/molecules/InvoiceList';
import { COLORS } from '../../../configs/colors';
import { Chip } from '../../../components/atoms/Chip';

export const ServiceList: React.FC = () => {
  const navigate = useNavigate();
  const apiRef = useGridApiRef();

  const [searchParams] = useSearchParams();
  const reportCode = searchParams.get('savedReportCode');
  const locationId = searchParams.get('locationId');
  const includeChildRecords = searchParams.get('includeChildRecords');
  const isFromStructures = searchParams.get('isFromStructures');
  const importSessionId = searchParams.get('importSessionId');
  const clientCode = searchParams.get('clientCode');
  const serviceTypeName = searchParams.get('serviceTypeName');
  const serviceStatusGroup = searchParams.get('serviceStatusGroup');
  const initialFilterModel = importSessionId ?
    [{ columnField: 'importSessionId', operatorValue: 'equals', value: importSessionId }] :
    clientCode && serviceTypeName && serviceStatusGroup ? getServiceActivityFilters(clientCode, serviceTypeName as string, serviceStatusGroup as string) : [];

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: importSessionId || (clientCode && serviceTypeName && serviceStatusGroup) ? initialFilterModel : [] });
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [rowCountState, setRowCountState] = useState(0);
  const [services, setServices] = useState<ServiceListDataType[]>([]);
  const [statusesList, setStatusesList] = useState<LabelValue[]>([]);
  const [actionTypesList, setActionTypesList] = useState<LabelValue[]>([]);
  const [processTypesList, setProcessTypesList] = useState<LabelValue[]>([]);
  const [selectedServices, setSelectedServices] = useState<SelectedServiceListDataType[]>([]);
  const [selectedServicesUsingCsv, setSelectedServicesUsingCsv] = useState<SelectedServiceListDataType[]>([]);
  const [BulkUpdateMessage, setBulkUpdateMessage] = useState<any>('');
  const [permissions, setPermissions] = useState<CustomPermission[]>([]);
  const [reportName, setReportName] = useState('');
  const [reportColumnSelectionItems, setReportColumnSelectionItems] = useState<Array<ColumnSelectionItem>>();
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [newReportData, setNewReportData] = useState<PropReportDataType>();
  const [savedReportMessage, setSavedReportMessage] = useState('');
  const [reportViewCode, setReportViewCode] = useState<string>();
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [fetchDataGrid, setFetchDataGrid] = useState<boolean>(false);
  const [showImage, setShowImage] = useState<boolean>(false);
  const [imageURL, setImageURL] = useState<string>('');
  const [fileAttachmentURL, setFileAttachmentURL] = useState<FileAttachmentURLType>(SERVICE_ATTACHMENT_FILE_URL_DATA);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const isMobileView = useMediaQuery(ScreenSize.MOBILE);
  const [selectedCellData, setSelectedCellData] = useState<ServiceListDataType | undefined>();
  const [billingData, setBillingData] = useState<ServiceBillingInfo>(SERVICE_BILLING_DATA);
  const [multiSelectTypesList, setMultiSelectTypesList] = useState<ServiceFilterMultiSelectType>();
  const [filterStatusesList, setFilterStatusesList] = useState<LabelValue[]>([]);
  const [columnSelectionData, setColumnSelectionData] = useState(columnSelectionItems);
  const [firstLoad, setFirstLoad] = useState<boolean>(false);
  const [filterSearch, setFilterSearch] = useState(false);

  const { width } = useWindowDimensions();

  const useAddNewReportQuery = useAddNewReport();
  const useOverwriteReportQuery = useUpdateReportByCode(reportCode || '');
  const { data: reportQueryData, refetch: refetchGetReportByCodeQuery } = useGetReportByCode(reportCode || '');
  const defaultReportData = useGetDefaultReportByReportType(SavedReportTypeList.ServiceList);
  const getUploadedFilePresignedUrlByKey = useGetUploadedFilePresignedUrlByKey(fileAttachmentURL.key, fileAttachmentURL.mimeType, fileAttachmentURL.contentDispositionType);
  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    setFirstLoad(true);
  }, []);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    const filteredColumnSelectionItems = handleServiceListCoulumnPermissions(columnSelectionItems, permissions);
    setColumnSelectionData(filteredColumnSelectionItems);
  }, [columnSelectionItems, permissions]);

  const getMobileViewColumns = (reportColumnSelectionItems: Array<ColumnSelectionItem>, row: any) => {
    const mobileDataItem: MobileDataGridItemEntryData[] = [];

    reportColumnSelectionItems.forEach(reportColumnSelectionItem => {
      reportColumnSelectionItem.value?.forEach(column => {
        if (column.value) {
          let value: string | ReactNode = row[column.field];
          let textValue = '';
          switch (column.field) {
            case 'clientHierarchy': {
              if (row.logoPic && row.logoPic !== null) {
                value = <img height={'25px'} src={row.logoPic} alt={row.clientHierarchy} />;
              }
              break;
            }
            case 'creationNotes': {
              value = row.creationNotes ? <Interweave content={row.creationNotes} /> : '';
              textValue = `${row.creationNotes}`;
              break;
            }
            case 'createdAt': {
              value = formatDate(row.createdAt);
              break;
            }
            case 'tags': {
              value = row.tag ? <TagCell tags={JSON.parse(row.tags)} /> : '';
              textValue = row.tags;
              break;
            }
            case 'scheduledDate': {
              value = formatDate(row.scheduledDate);
              break;
            }
            case 'followUpDate': {
              value = formatDate(row.followUpDate);
              break;
            }
            case 'updatedAt': {
              value = formatDate(row.updatedAt);
              break;
            }
            case 'lastUpdatedNote': {
              value = row.lastUpdatedNote ? <Interweave content={row.lastUpdatedNote} /> : '';
              textValue = row.lastUpdatedNote;
              break;
            }
            case 'specialScheduled': {
              value = formatDate(row.specialScheduled);
              break;
            }
            case 'tradingHours': {
              value = row.tradingHours ? <Interweave content={row.tradingHours} /> : '';
              textValue = row.tradingHours;
              break;
            }
            case 'linkedServices': {
              if (row.linkedServices) {
                value = row.linkedServices.split(', ').map((serviceID: string) =>
                  <><Link key={serviceID} href={`services/${serviceID}`}>{serviceID}</Link>&nbsp;</>
                );
                textValue = row.linkedServices;
              }
              break;
            }
            case 'linkedShipments': {
              if (row.linkedShipments) {
                value = row.linkedShipments.split(', ').map((shipmentID: string) =>
                  <><Link key={shipmentID} href={`shipments/${shipmentID}`}>{shipmentID}</Link>&nbsp;</>
                );
              }
              break;
            }
            case 'closedAt': {
              value = formatDate(row.closedAt);
              break;
            }
            case 'clientSLASummary': {
              value = <Typography textColor={row.clientSLASummary?.split(' , ')[1] === 'breached' ? COLORS.Red : ''}>{getDateFromTimestamp(row.clientSLASummary?.split(' , ')[0])}</Typography>;
              break;
            }
            case 'opsSLASummary': {
              value = <Typography textColor={row.opsSLASummary?.split(' , ')[1] === 'breached' ? COLORS.Red : ''}>{getDateFromTimestamp(row.opsSLASummary?.split(' , ')[0])}</Typography>;
              break;
            }
            case 'interactionTextbox': {
              value = row.interactionTextbox ? <Interweave content={row.interactionTextbox} /> : '';
              textValue = row.interactionTextbox;
              break;
            }
            case 'interactionDropdown': {
              value = row.interactionDropdown ? <Interweave content={row.interactionDropdown} /> : '';
              textValue = row.interactionDropdown;
              break;
            }
            case 'interactionRadiobutton': {
              value = row.interactionRadiobutton ? <Interweave content={row.interactionRadiobutton} /> : '';
              textValue = row.interactionRadiobutton;
              break;
            }
            case 'interactionCheckbox': {
              value = row.interactionCheckbox ? <Interweave content={row.interactionCheckbox} /> : '';
              textValue = row.interactionCheckbox;
              break;
            }
            case 'pendedNotes': {
              value = row.pendedNotes ? <Interweave content={row.pendedNotes} /> : '';
              textValue = row.pendedNotes;
              break;
            }
            case 'billingNotes': {
              value = row.billingNotes ? <Interweave content={row.billingNotes} /> : '';
              textValue = row.billingNotes;
              break;
            }
            case 'allNotes': {
              value = row.allNotes ? <Interweave content={row.allNotes} /> : '';
              textValue = row.allNotes;
              break;
            }
            case 'serviceAddress': {
              value = '';
              mobileDataItem.push({
                title: '',
                value: row.line1,
                valueLinkUrl: `/services/${row.serviceId}`
              }, {
                title: '',
                value: row.line2,
                valueLinkUrl: `/services/${row.serviceId}`
              }, {
                title: '',
                value: row.city,
                valueLinkUrl: `/services/${row.serviceId}`
              }, {
                title: '',
                value: row.stateName,
                valueLinkUrl: `/services/${row.serviceId}`
              },{
                title: '',
                value: row.suburb,
                valueLinkUrl: `/services/${row.serviceId}`
              }, {
                title: '',
                value: row.postCode,
                valueLinkUrl: `/services/${row.serviceId}`
              }, {
                title: '',
                value: row.countryName,
                valueLinkUrl: `/services/${row.serviceId}`
              });
              break;
            }
            case 'nodeReferences': {
              value = '';
              mobileDataItem.push({
                title: '',
                value: row.nodeReference1,
                valueLinkUrl: `/services/${row.serviceId}`
              }, {
                title: '',
                value: row.nodeReference2,
                valueLinkUrl: `/services/${row.serviceId}`
              }, {
                title: '',
                value: row.nodeReference3,
                valueLinkUrl: `/services/${row.serviceId}`
              });
              break;
            }
            case 'partActions': {
              value = '';
              mobileDataItem.push({
                title: '',
                value: row.serial1sIn,
                valueLinkUrl: `/services/${row.serviceId}`
              }, {
                title: '',
                value: row.serialisedPartTypesIn,
                valueLinkUrl: `/services/${row.serviceId}`
              }, {
                title: '',
                value: row.serial1sOut,
                valueLinkUrl: `/services/${row.serviceId}`
              }, {
                title: '',
                value: row.serialisedPartTypesOut,
                valueLinkUrl: `/services/${row.serviceId}`
              }, {
                title: '',
                value: row.nonSerialisedPartTypesIn,
                valueLinkUrl: `/services/${row.serviceId}`
              });
              break;
            }
            case 'surveyColumns': {
              value = '';
              mobileDataItem.push({
                title: '',
                value: row.interactionTextbox,
                valueLinkUrl: `/services/${row.serviceId}`
              }, {
                title: '',
                value: row.interactionRichTextbox ? <Interweave content={row.interactionRichTextbox} /> : '',
                valueLinkUrl: `/services/${row.serviceId}`
              }, {
                title: '',
                value: row.interactionDropdown,
                valueLinkUrl: `/services/${row.serviceId}`
              }, {
                title: '',
                value: row.interactionCheckbox,
                valueLinkUrl: `/services/${row.serviceId}`
              }, {
                title: '',
                value: row.interactionGetParameter,
                valueLinkUrl: `/services/${row.serviceId}`
              }, {
                title: '',
                value: row.interactionRadiobutton,
                valueLinkUrl: `/services/${row.serviceId}`
              });
              break;
            }
            case 'partsPending': {
              value = '';
              mobileDataItem.push({
                title: '',
                value: row.pendedParts,
                valueLinkUrl: `/services/${row.serviceId}`
              }, {
                title: '',
                value: row.pendedNotes,
                valueLinkUrl: `/services/${row.serviceId}`
              });
              break;
            }
            default:
              break;
          }

          if (value && value !== '-') {
            mobileDataItem.push({
              title: '',
              textValue: textValue,
              value: value,
              valueLinkUrl: `/services/${row.serviceId}`
            });
          }
        }
      });
    });

    return mobileDataItem.filter(dataItem => dataItem.value || dataItem.textValue);
  };

  useEffect(() => {
    setIsMobile(isMobileView);
  }, [isMobileView]);

  useEffect(() => {
    setColumns([
      {
        field: 'serviceId',
        headerName: 'Service ID',
        width: 100,
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toString(),
        renderCell: (params: GridRenderCellParams) =>
          isUserHasPermission(ActionPermissions.Service_Edit_View_Service, getUserPermissionsQuery.data) ?
            <Link href={`services/${params.row.serviceId}`}>{params.row.serviceId}</Link> :
            params.row.serviceId
      },
      ... isUserHasPermission(ActionPermissions.Service_Search_Columns_Service_details, getUserPermissionsQuery.data) ? [
        {
          field: 'serviceTypeName',
          headerName: 'Service Type Name',
          type: 'singleSelect',
          valueOptions: multiSelectTypesList?.serviceTypes,
          valueGetter: (params: GridValueGetterParams) => params.row?.serviceTypeName,
          width: 200
        },
        {
          field: 'serviceTypeCode',
          headerName: 'Service Type Code',
          type: 'string',
          width: 200
        },
        {
          field: 'actionTypeName',
          headerName: 'Action Type',
          type: 'singleSelect',
          valueOptions: multiSelectTypesList?.actionTypes,
          valueGetter: (params: GridValueGetterParams) => params.row?.actionTypeName,
          width: 200
        },
        {
          field: 'processTypeName',
          headerName: 'Process Type',
          type: 'singleSelect',
          valueOptions: multiSelectTypesList?.processTypes,
          valueGetter: (params: GridValueGetterParams) => params.row?.processTypeName,
          width: 200
        },
        {
          field: 'serviceParams',
          headerName: 'Service Params',
          type: 'string',
          width: 200
        },
        {
          field: 'clientHierarchy',
          headerName: 'Client',
          width: 150,
          renderCell: (params: GridRenderCellParams) => {
            if (params.row.logoPic && params.row.logoPic !== null)
              return (
                <LogoImageBox>
                  <img src={params.row.logoPic} alt={params.row.clientHierarchy} />
                </LogoImageBox>
              );
            else
              return params.row.clientHierarchy;
          },
        },
        {
          field: 'clientName',
          headerName: 'Client Name',
          type: 'string',
          width: 200
        },
        {
          field: 'clientCode',
          headerName: 'Client Code',
          type: 'string',
          width: 200
        },
        {
          field: 'contractName',
          headerName: 'Contract',
          type: 'string',
          width: 200
        },
        {
          field: 'contractCode',
          headerName: 'Contract Code',
          type: 'string',
          width: 200
        },
        {
          field: 'brandName',
          headerName: 'Brand',
          type: 'string',
          width: 200
        },
        {
          field: 'priorityCode',
          headerName: 'Priority',
          type: 'string',
          width: 150,
          renderCell: (params: GridRenderCellParams) => {
            if (params.row.priority)
              return (
                <Chip label={params.row.priority.shortName} backgroundColor={params.row.priority.color} borderColor={params.row.priority.color} textColor="#ffffff" />
              );
            else
              return '';
          },
        },
        {
          field: 'priorityDescription',
          headerName: 'Priority Description',
          type: 'string',
          width: 200
        },
        {
          field: 'reportedSerial',
          headerName: 'Reported Serial',
          type: 'string',
          width: 200
        },
        {
          field: 'reportedModel',
          headerName: 'Reported Model',
          type: 'string',
          width: 200
        },
        {
          field: 'reportedModelSerial',
          headerName: 'Reported Model/Serial',
          type: 'string',
          width: 200
        },
        {
          field: 'reportedFault',
          headerName: 'Reported Fault',
          type: 'string',
          width: 200
        },
        {
          field: 'reportedFaultDescription',
          headerName: 'Reported Fault Description',
          type: 'string',
          width: 200
        },
        {
          field: 'reportedFaultDescriptionCombine',
          headerName: 'Reported Fault/Description',
          type: 'string',
          width: 200
        },
        {
          field: 'creationNotes',
          headerName: 'Creation Notes',
          type: 'string',
          width: 300,
          renderCell: (params: any) => <Interweave content={params.row.creationNotes} />
        },
        {
          field: 'createdAt',
          headerName: 'Created At',
          type: 'date',
          width: 200,
          valueGetter: (params: GridValueGetterParams) => formatDate(params.row.createdAt)
        },
        {
          field: 'createdBy',
          headerName: 'Created By',
          type: 'string',
          width: 200
        },
        {
          field: 'reference1',
          headerName: 'Reference #1',
          type: 'string',
          width: 200
        },
        {
          field: 'reference2',
          headerName: 'Reference #2',
          type: 'string',
          width: 200
        },
        {
          field: 'reference3',
          headerName: 'Reference #3',
          type: 'string',
          width: 200
        },
        {
          field: 'tags',
          headerName: 'Tags',
          width: 300,
          renderCell: (params: GridRenderCellParams) => {
            if (params.row.tags)
              return (
                <TagCell tags={JSON.parse(params.row.tags)} />
              );
            else
              return '';
          },
        },
      ] : [],
      ... isUserHasPermission(ActionPermissions.Service_Search_Columns_Statuse_Allocation, getUserPermissionsQuery.data) ? [
        {
          field: 'serviceStatusName',
          headerName: 'Status Name',
          type: 'singleSelect',
          valueOptions: filterStatusesList,
          valueGetter: (params: GridValueGetterParams) => params.row?.serviceStatusName,
          width: 200
        },
        {
          field: 'primaryAllocation',
          headerName: 'Primary Allocation',
          type: 'string',
          width: 200
        },
        {
          field: 'primaryAllocationNode',
          headerName: 'Primary Allocation Node Name',
          type: 'string',
          width: 220
        },
        {
          field: 'secondaryAllocation',
          headerName: 'Secondary Allocation',
          type: 'string',
          width: 200
        },
        {
          field: 'secondaryAllocationNode',
          headerName: 'Secondary Allocation Node Name',
          type: 'string',
          width: 250
        },
        {
          field: 'scheduledDate',
          headerName: 'Scheduled Date',
          type: 'date',
          width: 200,
          valueGetter: (params: GridValueGetterParams) => formatDate(params.row.scheduledDate, undefined, true)
        },
        {
          field: 'followUpDate',
          headerName: 'Follow Up Date',
          type: 'date',
          width: 200,
          valueGetter: (params: GridValueGetterParams) => formatDate(params.row.followUpDate)
        },
        {
          field: 'updatedAt',
          headerName: 'Last Updated At',
          type: 'date',
          width: 200,
          valueGetter: (params: GridValueGetterParams) => formatDate(params.row.updatedAt)
        },
        {
          field: 'updatedBy',
          headerName: 'Last Updated By',
          type: 'string',
          width: 200
        },
        {
          field: 'lastUpdatedNote',
          headerName: 'Last Updated Notes',
          type: 'string',
          width: 300,
          renderCell: (params: any) => <Interweave content={params.row.lastUpdatedNote} />
        },
        {
          field: 'specialScheduled',
          headerName: 'Special Scheduled',
          type: 'date',
          width: 200,
          valueGetter: (params: GridValueGetterParams) => formatDate(params.row.specialScheduled)
        },
      ] : [],
      ... isUserHasPermission(ActionPermissions.Service_Search_Columns_Structure, getUserPermissionsQuery.data) ? [
        {
          field: 'locationName',
          headerName: 'Location Name',
          type: 'string',
          width: 200
        },
        {
          field: 'structureNodeName',
          headerName: 'Structure Node Name',
          type: 'string',
          width: 200
        },
        {
          field: 'structureNodeId',
          headerName: 'Structure Node ID',
          width: 130,
          type: 'number',
          valueFormatter: (params: GridValueFormatterParams) => params.value?.toString()
        },
        {
          field: 'structureNodeNotes',
          headerName: 'Structure Node Notes',
          type: 'string',
          width: 200
        },
        {
          field: 'subLocationName',
          headerName: 'Sublocation Name',
          type: 'string',
          width: 200
        },
        {
          field: 'subLocationId',
          headerName: 'Sublocation ID',
          width: 130,
          type: 'number',
          valueFormatter: (params: GridValueFormatterParams) => params.value?.toString()
        },
        {
          field: 'line1',
          headerName: 'Line 1',
          type: 'string',
          width: 100
        },
        {
          field: 'line2',
          headerName: 'Line 2',
          type: 'string',
          width: 100
        },
        {
          field: 'suburb',
          headerName: 'Suburb',
          type: 'string',
          width: 100
        },
        {
          field: 'city',
          headerName: 'City',
          type: 'string',
          width: 100
        },
        {
          field: 'stateName',
          headerName: 'State',
          type: 'string',
          width: 100
        },
        {
          field: 'postCode',
          headerName: 'Post Code',
          type: 'string',
          width: 100
        },
        {
          field: 'countryName',
          headerName: 'Country',
          type: 'string',
          width: 120
        },
        {
          field: 'mainAddress',
          headerName: 'Main Address',
          type: 'string',
          width: 200
        },
        {
          field: 'logisticsAddress',
          headerName: 'Logistics Address',
          type: 'string',
          width: 200
        },
        {
          field: 'regionType',
          headerName: 'Region Type',
          type: 'string',
          width: 200
        },
        {
          field: 'serviceContact',
          headerName: 'Service Contact',
          type: 'string',
          width: 200
        },
        {
          field: 'mainContact',
          headerName: 'Main Contact',
          type: 'string',
          width: 200
        },
        {
          field: 'nodeReference1',
          headerName: 'Node Reference #1',
          type: 'string',
          width: 200
        },
        {
          field: 'nodeReference2',
          headerName: 'Node Reference #2',
          type: 'string',
          width: 200
        },
        {
          field: 'nodeReference3',
          headerName: 'Node Reference #3',
          type: 'string',
          width: 200
        },
        {
          field: 'nodeSiteSchema',
          headerName: 'Node Site Schema',
          type: 'string',
          width: 300
        },
        {
          field: 'tradingHours',
          headerName: 'Trading Hours',
          type: 'string',
          width: 300,
          renderCell: (params: any) => <Interweave content={params.row.tradingHours} />
        },
      ] : [],
      ... isUserHasPermission(ActionPermissions.Service_Search_Columns_Part_Details, getUserPermissionsQuery.data) ? [
        {
          field: 'partTypeId',
          headerName: 'Part Type ID',
          width: 100,
          type: 'number',
          valueFormatter: (params: GridValueFormatterParams) => params.value?.toString()
        },
        {
          field: 'partTypeName',
          headerName: 'Part Type Name',
          type: 'string',
          width: 200
        },
        {
          field: 'partNo',
          headerName: 'Part No.',
          width: 100,
          type: 'string'
        },
        {
          field: 'partSerial1',
          headerName: 'Serial 1',
          type: 'string',
          width: 200
        },
        {
          field: 'partSerial2',
          headerName: 'Serial 2',
          type: 'string',
          width: 200
        },
        {
          field: 'partSerial3',
          headerName: 'Serial 3',
          type: 'string',
          width: 200
        },
        {
          field: 'softwareVersion',
          headerName: 'Software Ver',
          type: 'string',
          width: 200
        },
        {
          field: 'firmwareVersion',
          headerName: 'Firmware Ver',
          type: 'string',
          width: 200
        },
        {
          field: 'hardwareRevision',
          headerName: 'Hardware Rev',
          type: 'string',
          width: 200
        },
        {
          field: 'fleetTag',
          headerName: 'Fleet Tag',
          type: 'string',
          width: 200
        },
      ] : [],
      ... isUserHasPermission(ActionPermissions.Service_Search_Columns_Linked_Records, getUserPermissionsQuery.data) ? [
        {
          field: 'linkedServices',
          headerName: 'Linked Service #',
          width: 200,
          renderCell: (params: GridRenderCellParams) => {
            if (params.row.linkedServices) {
              return (
                params.row.linkedServices.split(', ').map((serviceID: string) =>
                  <><Link key={serviceID} href={`services/${serviceID}`}>{serviceID}</Link>&nbsp;</>
                )
              );
            } else {
              return '';
            }
          }
        },
        {
          field: 'linkedShipments',
          headerName: 'Linked Shipments',
          width: 200,
          renderCell: (params: GridRenderCellParams) => {
            if (params.row.linkedShipments) {
              return (
                params.row.linkedShipments.split(', ').map((shipmentID: string) =>
                  <><Link key={shipmentID} href={`shipments/${shipmentID}`}>{shipmentID}</Link>&nbsp;</>
                )
              );
            } else {
              return '';
            }
          }
        },
        {
          field: 'linkedShipmentConnotes',
          headerName: 'Linked Shipments Connotes',
          type: 'string',
          width: 200
        },
      ] : [],
      ... isUserHasPermission(ActionPermissions.Service_Search_Columns_Progress_Data, getUserPermissionsQuery.data) ? [
        {
          field: 'daysOpen',
          headerName: 'Days Open',
          width: 100,
          type: 'number',
          valueFormatter: (params: GridValueFormatterParams) => params.value?.toString()
        },
        {
          field: 'closedAt',
          headerName: 'Closed At',
          type: 'date',
          width: 200,
          valueGetter: (params: GridValueGetterParams) => formatDate(params.row.closedAt)
        },
        {
          field: 'attendanceCount',
          headerName: '# of Attendances',
          width: 150,
          type: 'number',
          valueFormatter: (params: GridValueFormatterParams) => params.value?.toString()
        },
        {
          field: 'reopensCount',
          headerName: '# of Re-opens',
          width: 130,
          type: 'number',
          valueFormatter: (params: GridValueFormatterParams) => params.value?.toString()
        },
        {
          field: 'uniqueTechniciansCount',
          headerName: '# of Unique Technicians',
          width: 180,
          type: 'number',
          valueFormatter: (params: GridValueFormatterParams) => params.value?.toString()
        },
        {
          field: 'observedFault',
          headerName: 'Observed Fault',
          type: 'string',
          width: 200
        },
        {
          field: 'observedFaultDescription',
          headerName: 'Observed Fault Description',
          type: 'string',
          width: 200
        },
        {
          field: 'solutionAction',
          headerName: 'Solution Action',
          type: 'string',
          width: 200
        },
        {
          field: 'solutionActionPartType',
          headerName: 'Solution Action Part Type Name',
          type: 'string',
          width: 230
        },
        {
          field: 'serial1sIn',
          headerName: 'Serial 1\'s In',
          type: 'string',
          width: 200
        },
        {
          field: 'serialisedPartTypesIn',
          headerName: 'Serialised Part Type Name\'s In',
          type: 'string',
          width: 230
        },
        {
          field: 'serial1sOut',
          headerName: 'Serial 1\'s Out',
          type: 'string',
          width: 200
        },
        {
          field: 'serialisedPartTypesOut',
          headerName: 'Serialised Part Type Name\'s Out',
          type: 'string',
          width: 230
        },
        {
          field: 'nonSerialisedPartTypesIn',
          headerName: 'Non Serialised Part Type Name\'s In',
          type: 'string',
          width: 250
        },
        {
          field: 'attachments',
          headerName: 'Attachments',
          type: 'string',
          width: 300,
          renderCell: (params: any) => {
            return (
              <Grid container spacing={1} direction="row">
                {params.row.attachments && JSON.parse(params.row.attachments)?.map((attachment: { fileName: string; key: string }, index: number) => (
                  <Box key={index} onClick={() => handleOpen(attachment)}>
                    <Interweave content={formatServiceAttachmentsData(attachment)} />
                    {index !== JSON.parse(params.row.attachments).length - 1 && ', \n'}
                  </Box>
                ))}
              </Grid>
            );
          }
        },
      ] : [],
      ... isUserHasPermission(ActionPermissions.Service_Search_Columns_SLA, getUserPermissionsQuery.data) ? [
        {
          field: 'clientSLASummary',
          headerName: 'Client SLA Summary',
          type: 'string',
          width: 200,
          renderCell: (params: any) => {
            return (
              <Typography textColor={params.row.clientSLASummary?.split(' , ')[1] === 'breached' ? COLORS.Red : ''}>{getDateFromTimestamp(params.row.clientSLASummary?.split(' , ')[0])}</Typography>
            );
          }
        },
        {
          field: 'opsSLASummary',
          headerName: 'Ops SLA Summary',
          type: 'string',
          width: 200,
          renderCell: (params: any) => {
            return (
              <Typography textColor={params.row.opsSLASummary?.split(' , ')[1] === 'breached' ? COLORS.Red : ''}>{getDateFromTimestamp(params.row.opsSLASummary?.split(' , ')[0])}</Typography>
            );
          }
        },
      ] : [],
      ... isUserHasPermission(ActionPermissions.Service_Search_Columns_Interactions, getUserPermissionsQuery.data) ? [
        {
          field: 'interactionTextbox',
          headerName: 'Interaction Textbox',
          type: 'string',
          width: 200,
          renderCell: (params: any) => <Interweave content={params.row.interactionTextbox} />
        },
        {
          field: 'interactionRichTextbox',
          headerName: 'Interaction Rich Textbox',
          type: 'string',
          width: 250,
          renderCell: (params: any) => <Interweave content={params.row.interactionRichTextbox} />
        },
        {
          field: 'interactionDropdown',
          headerName: 'Interaction Combobox',
          type: 'string',
          width: 200,
          renderCell: (params: any) => <Interweave content={params.row.interactionDropdown} />
        },
        {
          field: 'interactionRadiobutton',
          headerName: 'Interaction Radiobox',
          type: 'string',
          width: 200,
          renderCell: (params: any) => <Interweave content={params.row.interactionRadiobutton} />
        },
        {
          field: 'interactionCheckbox',
          headerName: 'Interaction Checkbox',
          type: 'string',
          width: 200,
          renderCell: (params: any) => <Interweave content={params.row.interactionCheckbox} />
        },
        {
          field: 'interactionGetParameter',
          headerName: 'Interaction Get Parameter',
          type: 'string',
          width: 300,
          renderCell: (params: any) => <Interweave content={params.row.interactionGetParameter} />
        },
        {
          field: 'pendedParts',
          headerName: 'Pended Parts',
          type: 'string',
          width: 250
        },
        {
          field: 'pendedNotes',
          headerName: 'Pended Notes',
          type: 'string',
          width: 400,
          renderCell: (params: any) => <Interweave content={params.row.pendedNotes} />
        },
      ] : [],
      ... isUserHasPermission(ActionPermissions.Service_Search_Columns_Admin_And_Billing, getUserPermissionsQuery.data) ? [
        {
          field: 'reviewed',
          headerName: 'Reviewed',
          type: 'singleSelect',
          valueOptions: [
            { value: true, label: 'True' },
            { value: false, label: 'False' }
          ],
          width: 100,
          renderCell: (params: any) => {
            return (
              <SwitchGrid>
                <FormControlLabel
                  control={
                    <Switch
                      color={ColorProps.Success}
                      checked={params.row.reviewed}
                    />
                  }
                  label=""
                />
              </SwitchGrid>
            );
          },
          editable: true,
          renderEditCell: () => {
            return (
              <SwitchGrid>
                <FormControlLabel
                  control={
                    <Switch
                      color={ColorProps.Success}
                      checked={billingData.isReviewed}
                      onChange={(e) => setBillingData({ ...billingData, isReviewed: e.target.checked, updated: true })}
                    />
                  }
                  label=""
                />
              </SwitchGrid>
            );
          }
        },
        {
          field: 'billableType',
          headerName: 'Billable Type',
          type: 'string',
          width: 200,
          editable: true,
          renderEditCell: () => {
            return (
              <SelectMenu
                id="billable-type"
                label="Billable Type"
                items={getBillingTypesDropdownData(getServiceBillingTypes?.data || [])}
                selectedValue={billingData.billingTypeCode}
                onChange={(code) => setBillingData({ ...billingData, billingTypeCode: code, updated: true })}
              />
            );
          }
        },
        {
          field: 'clientInvoiced',
          headerName: 'Client Invoiced',
          type: 'singleSelect',
          valueOptions: [
            { value: true, label: 'True' },
            { value: false, label: 'False' }
          ],
          width: 100,
          renderCell: (params: any) => {
            return (
              <SwitchGrid>
                <FormControlLabel
                  control={
                    <Switch
                      color={ColorProps.Success}
                      checked={params.row.clientInvoiced}
                    />
                  }
                  label=""
                />
              </SwitchGrid>
            );
          },
          editable: true,
          renderEditCell: () => {
            return (
              <SwitchGrid>
                <FormControlLabel
                  control={
                    <Switch
                      color={ColorProps.Success}
                      checked={billingData?.isClientInvoiced}
                      onChange={(e) => setBillingData({ ...billingData, isClientInvoiced: e.target.checked, updated: true })}
                    />
                  }
                  label=""
                />
              </SwitchGrid>
            );
          }
        },
        {
          field: 'billingNotes',
          headerName: 'Billing Notes',
          type: 'string',
          width: 200,
          renderCell: (params: any) => <Interweave content={params.row.billingNotes} />,
          editable: true,
          renderEditCell: () => {
            return (
              <EditField
                isEditable={true}
                value={billingData.billingNotes}
                onChange={(text) => setBillingData({ ...billingData, billingNotes: text, updated: true })}
              />
            );
          }
        },
        {
          field: 'internalInvoices',
          headerName: 'Internal Invoices',
          type: 'string',
          width: 400,
          editable: true,
          renderEditCell: () => {
            return (
              <InvoiceList
                heading="Internal Invoices"
                isInlineEdit
                invoiceListValues={getInvoicesByType(billingData.invoices, InvoiceType.Internal)}
                onChange={(value) => setBillingData({ ...billingData, invoices: getInvoicesByType(billingData.invoices, InvoiceType.External).concat(value) })}
                onCreate={(value) => setBillingData({ ...billingData, invoices: [...billingData.invoices, { ...value, invoiceType: InvoiceType.Internal }], updated: true })}
                onDelete={(value) => setBillingData({ ...billingData, invoices: getInvoicesByType(billingData.invoices, InvoiceType.External).concat(value) })}
              />
            );
          }
        },
        {
          field: 'externalInvoices',
          headerName: 'External Invoices',
          type: 'string',
          width: 400,
          editable: true,
          renderEditCell: () => {
            return (
              <InvoiceList
                heading="External Invoices"
                isInlineEdit
                invoiceListValues={getInvoicesByType(billingData.invoices, InvoiceType.External)}
                onChange={(value) => setBillingData({ ...billingData, invoices: getInvoicesByType(billingData.invoices, InvoiceType.Internal).concat(value) })}
                onCreate={(value) => setBillingData({ ...billingData, invoices: [...billingData.invoices, { ...value, invoiceType: InvoiceType.External }], updated: true })}
                onDelete={(value) => setBillingData({ ...billingData, invoices: getInvoicesByType(billingData.invoices, InvoiceType.Internal).concat(value) })}
              />
            );
          }
        },
      ] : [],
      {
        field: 'allNotes',
        headerName: 'All Notes',
        type: 'string',
        width: 500,
        renderCell: (params) => <Interweave content={params.row.allNotes} />
      },
      {
        field: 'importSessionId',
        headerName: 'Import Session ID',
        type: 'string',
        width: 130
      },
      {
        field: MOBILE_DATA_GRID_COLUMN,
        headerName: '',
        width: (width - 20),
        renderCell: ({ row }: GridRenderCellParams) => {
          if (row.isHeaderRow && row.rowHeaderTitle) {
            return (
              <HeaderWrapper variant={TypographyVariantProps.Subtitle1}>
                {row.rowHeaderTitle}
              </HeaderWrapper>
            );
          }
          return (
            <MobileDataGridItem
              data={getMobileViewColumns(reportColumnSelectionItems || columnSelectionItems, row)}
            />
          );
        },
        sortable: false,
        filterable: false
      }
    ]);
  }, [permissions, billingData, multiSelectTypesList, filterStatusesList]);

  const onFilterChange = useCallback((newFilterModel: GridFilterModel) => {
    if ((importSessionId || (clientCode && serviceTypeName && serviceStatusGroup)) && !newFilterModel.items.length) {
      setFilterModel({ items: [ ...initialFilterModel, ...newFilterModel.items ] });
    } else if (defaultReportData.data && defaultReportData.data[0].dataGridProperties?.filterModel && !newFilterModel.items.length) {
      setFilterModel(defaultReportData.data && defaultReportData.data[0].dataGridProperties?.filterModel);
    } else if (reportQueryData?.dataGridProperties.filterModel && !newFilterModel.items.length) {
      setFilterModel(reportQueryData.dataGridProperties.filterModel);
    } else {
      setFilterModel(newFilterModel);
    }
  }, []);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  const handleSelectionModelChange = useCallback((selectionModel: GridSelectionModel) => {
    setSelectionModel(selectionModel);
  }, []);

  const getServicePaginatedData = useGetServicePaginatedData({ filterModel, page, pageSize, sortModel, locationId, includeChildRecords, isFromStructures }, reportColumnSelectionItems || columnSelectionData);
  const getServicesStatuses = useGetServicesStatuses();
  const getActionTypes = useGetActionTypes();
  const getGetProcessTypes = useGetProcessTypes();
  const updateBulkService = useUpdateBulkServiceList();
  const getBulkServicesByIds = useGetBulkServicesByIds();
  const getServiceBillingTypes = useGetServiceBillingTypes();
  const getBillingInfo = useGetBillingInfo(selectedCellData?.serviceId || 0);
  const updateBillingInfo = useUpdateBillingInfo(selectedCellData?.serviceId || 0);
  const getServiceActionProcessTypes = useGetServiceActionProcessTypes();
  
  useEffect(() => {
    !filterStatusesList.length && getServicesStatuses.refetch();
  }, [filterStatusesList]);

  useEffect(() => {
    selectedCellData && getBillingInfo.refetch();
  }, [selectedCellData]);

  useEffect(() => {
    selectedCellData && setBillingData({ ...getBillingInfo.data || SERVICE_BILLING_DATA, updated: false });
  }, [getBillingInfo.data, selectedCellData]);

  useEffect(() => {
    billingData.updated && updateBillingData();
    if (!billingData.updated && billingData.updated !== undefined) {
      const cellMode = apiRef.current.getCellMode(selectedCellData?.id || 0, selectedCellData?.fieldName || '');
      selectedCellData && cellMode === GridCellModes.View && apiRef.current.startCellEditMode({ id: selectedCellData.id, field: selectedCellData.fieldName || '' });
    }
  }, [billingData]);

  useEffect(() => {
    !reportCode && defaultReportData.refetch();
  }, [SavedReportTypeList.ServiceList]);

  useEffect(() => {
    if (!reportCode && !importSessionId && !clientCode && defaultReportData.data && defaultReportData.data?.length > 0) {
      setFilterModel(defaultReportData.data[0].dataGridProperties?.filterModel);
      setSortModel(defaultReportData.data[0].dataGridProperties?.sortModel);
      setReportColumnSelectionItems(defaultReportData.data[0].columnSelection);
      setPageSize(defaultReportData.data[0].dataGridProperties?.pageSize);
      defaultReportData.data[0].dataGridProperties?.columnOrder && setColumns(defaultReportData.data[0].dataGridProperties?.columnOrder);
    }
  }, [defaultReportData.data, reportCode]);

  useEffect(() => {
    if (reportCode) {
      refetchGetReportByCodeQuery();
      setReportViewCode(reportCode);
    }
  }, [reportCode, fetchDataGrid]);

  useEffect(() => {
    if (reportCode && reportQueryData && firstLoad) {
      document.title = `${reportQueryData.name} (${reportCode}) | List Clients | ${PLATFORM_NAME}`;

      setFilterModel(reportQueryData.dataGridProperties.filterModel);
      setSortModel(reportQueryData.dataGridProperties.sortModel);
      setReportColumnSelectionItems(reportQueryData.columnSelection);
      setPageSize(reportQueryData.dataGridProperties.pageSize);
      setReportName(reportQueryData.name);
      setIsDefault(reportQueryData.isDefault);
      reportQueryData.dataGridProperties?.columnOrder && setColumns(reportQueryData.dataGridProperties?.columnOrder);
      setFirstLoad(false);
    }
  }, [reportQueryData, reportViewCode, columns, firstLoad]);

  useEffect(() => {
    setNewReportData({
      dataGridProperties: {
        filterModel: filterModel,
        pageSize: pageSize,
        sortModel: sortModel || [],
        columnOrder: columns
      },
      reportTypeName: SavedReportTypeList.ServiceList,
      reportName: reportName,
      isDefault: reportQueryData?.isDefault,
      id: reportQueryData?.id,
      reportOwner: reportQueryData?.createdBy,
      columnSelection: reportColumnSelectionItems
    });
  }, [filterModel, pageSize, isDefault, reportName, sortModel, columns]);

  useEffect(() => {
    if ((!reportCode || (reportCode && reportQueryData)) && (filterModel.items.length === 0 || checkListPagetFilterAvailability(filterModel) || sortModel?.some(sort => sort.field))) {
      getServicePaginatedData.refetch();
    }
  }, [filterModel, page, pageSize, sortModel, reportCode, isDefault, reportQueryData, reportColumnSelectionItems, locationId, includeChildRecords, isFromStructures, filterSearch]);

  useEffect(() => {
    const selectedValues = services.reduce((accumulator: SelectedServiceListDataType[], currentValue) => {
      if (selectionModel.includes(currentValue.id) && currentValue.serviceId) {
        const item = {
          id: currentValue.serviceId,
          isClosed: currentValue.isClosedServiceStatus,
          image: currentValue.logoPic || ''
        };
        return [...accumulator, item];
      }
      return accumulator;
    }, []);

    setSelectedServices([...selectedValues]);
  }, [selectionModel]);

  useEffect(() => {
    if (getServicePaginatedData.data) {
      // filter services logged against location based on search param 'locationId'
      const results = getServicePaginatedData.data.data;

      if (isMobile && results) {
        const clonedResults: ServiceListDataType[] = JSON.parse(JSON.stringify(results));
        const loggedinUserCheckedInServices: ServiceListDataType[] = JSON.parse(JSON.stringify(clonedResults.filter(service => service.isCheckedIn) || []));
        const allocatedServices: ServiceListDataType[] = JSON.parse(JSON.stringify(clonedResults.filter(service => service.isAllocated) || []));
        const allocatedAboveMeServices: ServiceListDataType[] = JSON.parse(JSON.stringify(clonedResults.filter(service => service.isAllocatedAboveMe) || []));
        let groupedServices: ServiceListDataType[] = [];
        let checkedInServicesHeaderRow: ServiceListDataType;
        let allocatedServicesHeaderRow: ServiceListDataType;
        let allocatedAboveMeServicesHeaderRow: ServiceListDataType;

        if (loggedinUserCheckedInServices?.length) {
          checkedInServicesHeaderRow = {
            id: Math.random(),
            isClosedServiceStatus: false,
            isHeaderRow: true,
            rowHeaderTitle: 'Checked In'
          };

          loggedinUserCheckedInServices.map(service => service.id = Math.random());
          groupedServices = [checkedInServicesHeaderRow, ...loggedinUserCheckedInServices];
        }
        if (allocatedServices?.length) {
          allocatedServicesHeaderRow = {
            id: Math.random(),
            isClosedServiceStatus: false,
            isHeaderRow: true,
            rowHeaderTitle: 'Allocated To Me'
          };

          allocatedServices.map(service => service.id = Math.random());
          groupedServices = [...groupedServices, allocatedServicesHeaderRow, ...allocatedServices];
        }

        if (allocatedAboveMeServices?.length) {
          allocatedAboveMeServicesHeaderRow = {
            id: Math.random(),
            isClosedServiceStatus: false,
            isHeaderRow: true,
            rowHeaderTitle: 'Allocated Above Me'
          };

          allocatedAboveMeServices.map(service => service.id = Math.random());
          groupedServices = [...groupedServices, allocatedAboveMeServicesHeaderRow, ...allocatedAboveMeServices];
        }

        if (groupedServices.length) {
          const otherServicesHeaderRow: ServiceListDataType = {
            id: Math.random(),
            isClosedServiceStatus: false,
            isHeaderRow: true,
            rowHeaderTitle: 'Other'
          };

          const otherServices: ServiceListDataType[] = clonedResults?.filter(service => {
            return !loggedinUserCheckedInServices.some(checkedInService => checkedInService.serviceId === service.serviceId)
              && !allocatedServices.some(allocatedService => allocatedService.serviceId === service.serviceId)
              && !allocatedAboveMeServices.some(allocatedService => allocatedService.serviceId === service.serviceId);
          });

          groupedServices = [...groupedServices, otherServicesHeaderRow, ...otherServices];
          setServices(groupedServices);
        }
        else {
          setServices(results || []);
        }
      }
      else {
        setServices(results || []);
      }

      setRowCountState(getServicePaginatedData.data.total || 0);
    }
  }, [getServicePaginatedData.data, isMobile]);

  useEffect(() => {
    if (getServicesStatuses.data) {
      setStatusesList(getServicesStatuses.data.filter((status) => status.isActive).map(listData => (
        {
          value: listData.code,
          label: listData.name,
          disabled: !listData.isActive
        }
      )));
      const openStatuses = getServicesStatuses.data.filter((status) => status.isActive && !status.isClosed).map(listData => (
        {
          value: listData.name,
          label: listData.name,
          disabled: !listData.isActive
        }
      ));
      const closedStatuses = getServicesStatuses.data.filter((status) => status.isActive && status.isClosed).map(listData => (
        {
          value: listData.name,
          label: listData.name,
          disabled: !listData.isActive
        }
      ));
      setFilterStatusesList(openStatuses.concat(closedStatuses));
    }
  }, [getServicesStatuses.data]);

  useEffect(() => {
    if (getServiceActionProcessTypes.data) {
      const filterData = getServiceActionProcessTypesForMultiSelectFilters(getServiceActionProcessTypes.data);
      setMultiSelectTypesList({
        serviceTypes: filterData.serviceTypesData,
        actionTypes: filterData.actionTypesData,
        processTypes: filterData.processTypesData
      });
    }
  }, [getServiceActionProcessTypes.data]);

  useEffect(() => {
    if (getActionTypes.data) {
      setActionTypesList(getActionTypes.data.map(listData => (
        { value: listData.code, label: listData.name }
      )));
    }
  }, [getActionTypes.data]);

  useEffect(() => {
    if (getGetProcessTypes.data) {
      setProcessTypesList(getGetProcessTypes.data.map(listData => (
        { value: listData.code, label: listData.name }
      )));
    }
  }, [getGetProcessTypes.data]);

  useEffect(() => {
    imageURL && setShowImage(true);
  }, [imageURL]);

  useEffect(() => {
    fileAttachmentURL?.key && getUploadedFilePresignedUrlByKey.refetch();
  }, [fileAttachmentURL?.key]);

  useEffect(() => {
    if (getUploadedFilePresignedUrlByKey.data?.url) {
      if (
        fileAttachmentURL.mimeType === FileMimeTypes.PNG ||
        fileAttachmentURL.mimeType === FileMimeTypes.JPEG ||
        fileAttachmentURL.mimeType === FileMimeTypes.JPG
      ) {
        setImageURL(getUploadedFilePresignedUrlByKey.data.url);
      } else {
        fileAttachmentURL.fileName && downloadFile(getUploadedFilePresignedUrlByKey.data?.url, fileAttachmentURL.fileName);
      }
      setFileAttachmentURL(SERVICE_ATTACHMENT_FILE_URL_DATA);
      getUploadedFilePresignedUrlByKey.remove();
    }
  }, [getUploadedFilePresignedUrlByKey.data]);

  const bulkEditServiceDropDownsDataset = {
    actionTypeItems: actionTypesList,
    processTypeItems: processTypesList,
    statusItems: statusesList,
    linkedFieldItems: linkedFieldItems
  };

  const bulkUpdateData = async (BulkServiceUpdateData: BulkEditServiceDataType) => {
    setBulkUpdateMessage('');
    const responce: any = await updateBulkService.mutateAsync(BulkServiceUpdateData);
    setBulkUpdateMessage(responce?.message ? responce?.message : 'Successfully Updated');
  };

  const retrieveDataByImportedBulkServiceIds = async (importedBulkServiceIds: number[]) => {
    const importServiceIdObject = {
      affectedServiceIds: importedBulkServiceIds
    };
    const serviceData: any = await getBulkServicesByIds.mutateAsync(importServiceIdObject);
    setSelectedServicesUsingCsv(serviceData);
  };

  const handleColumnsChange = (items: Array<ColumnSelectionItem>) => {
    setReportColumnSelectionItems(items);
  };

  const savedReport = async (newReportData: NewReportDataType) => {
    setSavedReportMessage('');
    const response: ReportResponse = await useAddNewReportQuery.mutateAsync(newReportData);
    setSavedReportMessage(response?.message ? response?.message : 'Successfully Created');
    const reportURL = generateReportURL(window.location.pathname, response.code);
    setOpenToast(true);
    navigate(reportURL, { replace: true });
  };

  const overwriteReport = async (overwriteReportData: OverwriteReportDataType) => {
    setSavedReportMessage('');
    const response: ReportResponse = await useOverwriteReportQuery.mutateAsync(overwriteReportData);
    setSavedReportMessage(response?.message ? response?.message : 'Successfully Updated');
    const reportURL = generateReportURL(window.location.pathname, response.code);
    setOpenToast(true);
    navigate(reportURL, { replace: true });
  };

  const handleOpen = (attachmentsData: { fileName: string; key: string }) => {
    const URL_data = getServiceAttachmentURLData(attachmentsData);
    if (URL_data) {
      const fileAttachmentData = {
        key: URL_data.key,
        mimeType: URL_data.mimeType,
        contentDispositionType: URL_data.contentDispositionType,
        fileName: URL_data.fileName
      };
      setFileAttachmentURL(fileAttachmentData);
    }
  };

  const handleCellClick = (params: GridCellParams) => {
    const clickedCellMode = apiRef.current.getCellMode(params.id, params.field);
    const selectedCellMode = apiRef.current.getCellMode(selectedCellData?.id || 0, selectedCellData?.fieldName || '');

    // Cell mode should be in view mode to set it to edit mode
    if (clickedCellMode === GridCellModes.View) {
      selectedCellMode === GridCellModes.Edit && apiRef.current.stopCellEditMode({ id: selectedCellData?.id || 0, field: selectedCellData?.fieldName || '' });
      // Check whether the clicked cell is an editable cell and set the new cell data
      if (params.isEditable) {
        setSelectedCellData({ ...params.row, fieldName: params.field });
      } else {
        selectedCellData && setSelectedCellData(undefined);
        billingData && setBillingData(SERVICE_BILLING_DATA);
      }
    }
  };

  const updateBillingData = async () => {
    await updateBillingInfo.mutateAsync(billingData);
    apiRef.current.stopCellEditMode({ id: selectedCellData?.id || 0, field: selectedCellData?.fieldName || '' });
    setSelectedCellData(undefined);
    setBillingData(SERVICE_BILLING_DATA);
    getServicePaginatedData.refetch();
  };

  const handleImageClose = () => {
    setShowImage(false);
    setImageURL('');
  };

  return (
    <>
      <GridContainer isMobile={isMobileView}>
        <Grid>
          <Snackbar
            open={openToast}
            autoHideDuration={4000}
            message={savedReportMessage ? savedReportMessage : 'Successfully Created'}
            onClose={() => setOpenToast(false)}
          />
        </Grid>
        <CustomDataGrid
          columns={getServicesListFilteredColumns(reportColumnSelectionItems || [], columns)}
          rows={services}
          exportFileName={ListType.ServiceList}
          enableCSVExport={isUserHasPermission(ActionPermissions.Service_Export, permissions)}
          columnSelectionItems={reportColumnSelectionItems ? reportColumnSelectionItems : columnSelectionData}
          filterMode={GridFeatureModeConstant.server}
          onFilterModelChange={(filterModel: GridFilterModel) => {
            onFilterChange(filterModel);
          }}
          loading={getServicePaginatedData.isLoading || getServicePaginatedData.isRefetching}
          rowCount={rowCountState}
          rowsPerPageOptions={[25, 50, 100]}
          pagination={true}
          page={page}
          pageSize={pageSize}
          paginationMode={GridFeatureModeConstant.server}
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          sortingMode={GridFeatureModeConstant.server}
          onSortModelChange={handleSortModelChange}
          selectionModel={selectionModel}
          onSelectionModelChange={handleSelectionModelChange}
          componentsProps={{
            toolbar: {
              printOptions: { disableToolbarButton: true }
            }
          }}
          enableBulkUpdate={isUserHasPermission(ActionPermissions.Service_Bulk_Update_Search, permissions)}
          bulkEditServiceDropDownsDataset={bulkEditServiceDropDownsDataset}
          selectedSevicesForBulkUpdate={selectedServices.length === 0 ? selectedServicesUsingCsv : selectedServices}
          bulkUpdateToastMessage={BulkUpdateMessage ?? ''}
          handleBulkUpdateSave={(BulkServiceUpdateData: BulkEditServiceDataType) => {
            bulkUpdateData(BulkServiceUpdateData);
          }}
          handleBulkUpdateImportedData={(data: object[]) => {
            const importedServiceIds: number[] = data.map((item: any) => {
              const id = parseInt(item.Service_Id);
              return id;
            });
            retrieveDataByImportedBulkServiceIds(importedServiceIds);
          }}
          filterModel={filterModel}
          newReportData={newReportData}
          handleSavedReport={(newReportData: NewReportDataType) => savedReport(newReportData)}
          handleOverwriteReport={(overwriteReportData: OverwriteReportDataType) => overwriteReport(overwriteReportData)}
          enableSavedReport={true}
          reportCode={reportViewCode}
          handleColumnsChange={handleColumnsChange}
          handleRefetch={() => setFetchDataGrid(!fetchDataGrid)}
          getRowHeight={() => 'auto'}
          sortModel={sortModel}
          disableDensitySelector={false}
          isMobile={isMobile}
          onDensityChange={(densitySelection) => {
            setIsMobile(densitySelection === CustomDensityOption.Mobile);
          }}
          onCellClick={handleCellClick}
          apiRef={apiRef}
          experimentalFeatures={{ newEditingApi: true }}
          serviceColumns={columns}
          setColumns={setColumns}
          filtersSearch={() => setFilterSearch(!filterSearch)}
        />
      </GridContainer>
      {imageURL &&
        <Modal
          open={showImage}
          onClose={handleImageClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ImageWrapper container onMouseDown={handleImageClose}>
            <Image src={imageURL}/>
          </ImageWrapper>
        </Modal>
      }
    </>
  );
};