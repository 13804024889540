import React, { useCallback, useEffect, useState } from 'react';
import { GridFeatureModeConstant, GridFilterModel, GridSortModel, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { Grid } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GridColDef, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid';
import { Chip } from '../../../components/atoms/Chip';
import { TagCell, TagCellItem } from '../../../components/molecules/TagCell';
import { ColumnSelectionItem } from '../../../components/templates/ColumnSelection';
import { CustomDataGrid } from '../../../components/layouts/CustomDataGrid';
import { LogoImageBox } from './UserList.styles';
import { ActionPermissions, ColorProps, CustomPermission, ListType, NewReportDataType, OverwriteReportDataType, PropReportDataType, ReportResponse } from '../../../@types';
import { formatDate } from '../../../utils/common';
import { useGetAllUsers, useGetUserSystemPermissions, UserListResponse } from '../../../queries/user-query';
import { isUserHasPermission } from '../../../configs/permissions';
import { Link } from '../../../components/atoms/Link';
import { useAddNewReport, useGetDefaultReportByReportType, useGetReportByCode, useUpdateReportByCode } from '../../../queries/report-query';
import { generateReportURL } from '../../../utils/report';
import { Snackbar } from '../../../components/atoms/Snackbar';
import { SavedReportTypeList } from '../../../configs/enums';
import { GridContainer } from '../../../styled/common.styles';
import { PLATFORM_NAME } from '../../../configs/common';
import { userListColumnSelectionItems } from '../../../constants/user.constant';

export const UserList: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const reportCode = searchParams.get('savedReportCode');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [users, getUsers] = useState<UserListResponse>();
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const [reportName, setReportName] = useState('');
  const [reportColumnSelectionItems, setReportColumnSelectionItems] = useState<Array<ColumnSelectionItem>>();
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [newReportData, setNewReportData] = useState<PropReportDataType>();
  const [savedReportMessage, setSavedReportMessage] = useState('');
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [reportViewCode, setReportViewCode] = useState<string>();
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [fetchDataGrid, setFetchDataGrid] = useState<boolean>(false);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [firstLoad, setFirstLoad] = useState<boolean>(false);
  const [filterSearch, setFilterSearch] = useState(false);

  const useAddNewReportQuery = useAddNewReport();
  const useOverwriteReportQuery = useUpdateReportByCode(reportCode || '');
  const { data: reportQueryData, refetch: refetchGetReportByCodeQuery } = useGetReportByCode(reportCode || '');
  const getAllUsers = useGetAllUsers({ filterModel, page, pageSize, sortModel });
  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const defaultReportData = useGetDefaultReportByReportType(SavedReportTypeList.UserList);

  useEffect(() => {
    setFirstLoad(true);
  }, []);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    setColumns([
      {
        field: 'id',
        headerName: 'User ID',
        width: 100,
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toString()
      },
      {
        field: 'firstName',
        headerName: 'First Name',
        type: 'string',
        width: 150
      },
      {
        field: 'lastName',
        headerName: 'Last Name',
        type: 'string',
        width: 150
      },
      {
        field: 'profilePic',
        headerName: 'Profile Photo',
        filterable: false,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams) => {
          if(params.row.profilePic)
            return (
              <LogoImageBox>
                <img src={params.row.profilePic} />
              </LogoImageBox>
            );
        }
      },
      {
        field: 'email',
        headerName: 'Login Email',
        renderCell: (params: GridRenderCellParams) => isUserHasPermission(ActionPermissions.Configure_User_Edit_General, getUserPermissionsQuery.data) ?
          <Link href={`/configure/users/${params.row.id}`}>{params.row.email}</Link> :
          params.row.email
        ,   
        width: 300,
        valueGetter: (params) => params.row.email
      },
      {
        field: 'tags',
        headerName: 'Tags',
        renderCell: (params: GridRenderCellParams) => <TagCell tags={params.row.tags.map((tagObject: any) => {
          const tag: TagCellItem = { title: tagObject.text, color: tagObject.colour, isSystem: tagObject.isSystem };
          return tag;
        })}/>,
        width: 200,
        valueGetter: (params) => params.row.tags?.map((tag: any) => tag.text).join(', ')
      },
      {
        field: 'isLocked',
        headerName: 'Is Locked',
        type: 'singleSelect',
        valueOptions: [
          { value: 'any', label: 'Any' },
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' }
        ],
        width: 100,
        renderCell: (params: GridRenderCellParams) => params.row.isLocked ? <Chip label="YES" color={ColorProps.Success} /> : <Chip label="NO" color={ColorProps.Error} />,
        valueGetter: (params: GridValueGetterParams) => params.row.isLocked
      },
      {
        field: 'isEmailVerified',
        headerName: 'Email Verified',
        type: 'singleSelect',
        valueOptions: [
          { value: 'any', label: 'Any' },
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' }
        ],
        width: 120,
        renderCell: (params: GridRenderCellParams) => params.row.isEmailVerified ? <Chip label="ACTIVATED" color={ColorProps.Success} /> : <Chip label="PENDING" color={ColorProps.Error} />,
        valueGetter: (params: GridValueGetterParams) => params.row.isEmailVerified
      },
      {
        field: 'lastActive',
        headerName: 'Last Active',
        type: 'date',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => formatDate(params.row.lastActive)
      },
      {
        field: 'contactEmail',
        headerName: 'Contact Email',
        type: 'string',
        width: 300
      },
      {
        field: 'timezoneName',
        headerName: 'Time Zone',
        width: 100,
        valueGetter: (params: GridValueGetterParams) => params.row.timezoneName
      },
      {
        field: 'isActive',
        headerName: 'Enabled',
        width: 100,
        type: 'singleSelect',
        valueOptions: [
          { value: 'any', label: 'Any' },
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' }
        ],
        renderCell: (params: GridRenderCellParams) => params.row.isActive ? <Chip label="YES" color={ColorProps.Success} /> : <Chip label="NO" color={ColorProps.Error} />,
        valueGetter: (params: GridValueGetterParams) => params.row.isActive
      },
      {
        field: 'siblingNodeId',
        headerName: 'Sibling ID',
        type: 'number',
        width: 150,
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toString()
      },
      {
        field: 'siblingNodeName',
        headerName: 'Node Name',
        type: 'string',
        width: 150,
      },
      {
        field: 'siblingParentNodeName',
        headerName: 'Parent Node Name',
        type: 'string',
        width: 150,
      },
      {
        field: 'siblingParentNodeTree',
        headerName: 'Parent Node Tree',
        type: 'string',
        width: 150,
      },
      {
        field: 'siblingNodeAddresses',
        headerName: 'Node Addresses',
        type: 'string',
        width: 200
      },
      {
        field: 'siblingNodeContacts',
        headerName: 'Node Contacts',
        type: 'string',
        width: 200
      },
      {
        field: 'siblingRefNumber1',
        headerName: 'Reference Number 1',
        type: 'string',
        width: 150,
      },
      {
        field: 'siblingRefNumber2',
        headerName: 'Reference Number 2',
        type: 'string',
        width: 150,
      },
      {
        field: 'siblingRefNumber3',
        headerName: 'Reference Number 3',
        type: 'string',
        width: 150,
      },
      {
        field: 'roleId',
        headerName: 'Role ID',
        type: 'number',
        width: 150,
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toString(),
        valueGetter: (params: GridValueGetterParams) => params.row.role.id
      },
      {
        field: 'roleName',
        headerName: 'Role Name',
        type: 'string',
        width: 150,
        valueGetter: (params: GridValueGetterParams) => params.row.role.name
      },
      {
        field: 'roleVisibilityRule',
        headerName: 'Role Visibility Rule',
        type: 'string',
        width: 150,
        valueGetter: (params: GridValueGetterParams) => params.row.role.visibilityRule
      },
      {
        field: 'roleActive',
        headerName: 'Role Active',
        type: 'singleSelect',
        valueOptions: [
          { value: 'any', label: 'Any' },
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' }
        ],
        width: 150,
        renderCell: (params: GridRenderCellParams) => params.row.role.isActive ? <Chip label="YES" color={ColorProps.Success} /> : <Chip label="NO" color={ColorProps.Error} />,
        valueGetter: (params: GridValueGetterParams) => params.row.role.isActive
      },
      {
        field: 'roleNotes',
        headerName: 'Role Notes',
        type: 'string',
        width: 150,
        valueGetter: (params: GridValueGetterParams) => params.row.role.notes
      },
      {
        field: 'roleUserCustomPermissions',
        headerName: 'User Custom Permissions',
        type: 'singleSelect',
        valueOptions: [
          { value: 'any', label: 'Any' },
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' }
        ],
        width: 150,
        renderCell: (params: GridRenderCellParams) => params.row.role.userCustomPermissions ? <Chip label="YES" color={ColorProps.Success} /> : <Chip label="NO" color={ColorProps.Error} />,
        valueGetter: (params: GridValueGetterParams) => params.row.role.userCustomPermissions
      },
      {
        field: 'userCreatedAt',
        headerName: 'User Created At',
        type: 'date',
        width: 150,
        valueGetter: (params: GridValueGetterParams) => formatDate(params.row.userCreatedAt),
      },
      {
        field: 'userCreatedBy',
        headerName: 'User Created By',
        type: 'string',
        width: 150,
      },
      {
        field: 'userUpdatedAt',
        headerName: 'User Updated At',
        type: 'date',
        width: 150,
        valueGetter: (params: GridValueGetterParams) => formatDate(params.row.userUpdatedAt),
      },
      {
        field: 'userUpdatedBy',
        headerName: 'User Updated By',
        type: 'string',
        width: 150,
      },
      {
        field: 'siblingCreatedAt',
        headerName: 'Sibling Created At',
        type: 'date',
        width: 150,
        valueGetter: (params: GridValueGetterParams) => formatDate(params.row.siblingCreatedAt),
      },
      {
        field: 'siblingCreatedBy',
        headerName: 'Sibling Created By',
        type: 'string',
        width: 150,
      },
      {
        field: 'siblingUpdatedAt',
        headerName: 'Sibling Updated At',
        type: 'date',
        width: 150,
        valueGetter: (params: GridValueGetterParams) => formatDate(params.row.siblingUpdatedAt),
      },
      {
        field: 'siblingUpdatedBy',
        headerName: 'Sibling Updated By',
        type: 'string',
        width: 150,
      },
    ]);
  }, [permissions]);

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    if (defaultReportData.data && defaultReportData.data[0].dataGridProperties?.filterModel && !filterModel.items.length) {
      setFilterModel(defaultReportData.data && defaultReportData.data[0].dataGridProperties?.filterModel);
    } else if (reportQueryData?.dataGridProperties.filterModel && !filterModel.items.length) {
      setFilterModel(reportQueryData.dataGridProperties.filterModel);
    } else {
      setFilterModel(filterModel);
    }
  }, []);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  useEffect(() => {
    !reportCode && defaultReportData.refetch();
  }, [SavedReportTypeList.UserList]);

  useEffect(() => {
    if (!reportCode && defaultReportData.data && defaultReportData.data?.length > 0) {
      setFilterModel(defaultReportData.data[0].dataGridProperties?.filterModel);
      setSortModel(defaultReportData.data[0].dataGridProperties?.sortModel);
      setReportColumnSelectionItems(defaultReportData.data[0].columnSelection);
      setPageSize(defaultReportData.data[0].dataGridProperties?.pageSize);
      defaultReportData.data[0].dataGridProperties?.columnOrder && setColumns(defaultReportData.data[0].dataGridProperties?.columnOrder);
    }   
  }, [defaultReportData.data, reportCode]);

  useEffect(() => {
    getAllUsers.data && getUsers(getAllUsers.data);
  }, [getAllUsers.data]);

  useEffect(() => {
    if (reportCode) {
      refetchGetReportByCodeQuery();
      setReportViewCode(reportCode);
    }
  }, [reportCode, fetchDataGrid]);

  useEffect(() => {
    if (reportCode && reportQueryData && firstLoad) {
      document.title = `${reportQueryData.name} (${reportCode}) | List Users | ${PLATFORM_NAME}`;

      setFilterModel(reportQueryData.dataGridProperties.filterModel);
      setSortModel(reportQueryData.dataGridProperties?.sortModel);
      setReportColumnSelectionItems(reportQueryData.columnSelection);
      setPageSize(reportQueryData.dataGridProperties.pageSize);
      setReportName(reportQueryData.name);
      setIsDefault(reportQueryData.isDefault);
      reportQueryData.dataGridProperties?.columnOrder && setColumns(reportQueryData.dataGridProperties?.columnOrder);
      setFirstLoad(false);
    }
  }, [reportQueryData, reportViewCode, columns, firstLoad]);

  useEffect(() => {
    setNewReportData({
      dataGridProperties: {
        filterModel: filterModel,
        pageSize: pageSize,
        sortModel: sortModel || [],
        columnOrder: columns
      },
      reportTypeName: SavedReportTypeList.UserList,
      reportName: reportName,
      isDefault: reportQueryData?.isDefault,
      id: reportQueryData?.id,
      reportOwner: reportQueryData?.createdBy,
      columnSelection: reportColumnSelectionItems
    });
  }, [filterModel, pageSize, isDefault, reportName, sortModel, columns]);

  useEffect(() => {
    if (!reportCode || (reportCode && reportQueryData)) {
      getAllUsers.refetch();  
    }
  }, [page, pageSize, filterModel, reportCode, isDefault, reportQueryData, sortModel, filterSearch]);

  const handleColumnsChange = (items: Array<ColumnSelectionItem>) => {
    setReportColumnSelectionItems(items);
  };

  const savedReport = async (newReportData: NewReportDataType) => {
    setSavedReportMessage('');
    const response: ReportResponse = await useAddNewReportQuery.mutateAsync(newReportData);
    setSavedReportMessage(response?.message ? response?.message : 'Successfully Created');
    const reportURL = generateReportURL(window.location.pathname, response.code);
    setOpenToast(true);
    navigate(reportURL, { replace: true });
  };

  const overwriteReport = async (overwriteReportData: OverwriteReportDataType) => {
    setSavedReportMessage('');
    const response: ReportResponse = await useOverwriteReportQuery.mutateAsync(overwriteReportData);
    setSavedReportMessage(response?.message ? response?.message : 'Successfully Updated');
    const reportURL = generateReportURL(window.location.pathname, response.code);
    setOpenToast(true);
    navigate(reportURL, { replace: true });
  };

  return (
    <GridContainer>
      <Grid>
        <Snackbar  
          open={openToast} 
          autoHideDuration={4000} 
          message={savedReportMessage ? savedReportMessage : 'Successfully Created'}
          onClose={()=> setOpenToast(false)}
        />
      </Grid>
      <CustomDataGrid
        columns={columns}
        rows={users?.data || []}
        rowCount={users?.total}
        exportFileName={ListType.UserList}
        enableCSVExport={isUserHasPermission(ActionPermissions.Configure_User_Export, permissions)}
        columnSelectionItems={reportColumnSelectionItems ? reportColumnSelectionItems : userListColumnSelectionItems}
        filterMode={GridFeatureModeConstant.server}
        onFilterModelChange={(filterModel: GridFilterModel) => onFilterChange(filterModel)}
        onSortModelChange={handleSortModelChange}
        loading={getAllUsers.isLoading || getAllUsers.isRefetching}
        rowsPerPageOptions={[25, 50, 100]}
        pagination={true}
        page={page}
        pageSize={pageSize}
        paginationMode={GridFeatureModeConstant.server}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        initialState={{
          columns: {
            columnVisibilityModel: {
              siblingUpdatedBy: false
            },
          },
        }}
        componentsProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true }
          }
        }}
        filterModel={filterModel}
        newReportData={newReportData} 
        handleSavedReport={(newReportData: NewReportDataType) => savedReport(newReportData)}
        handleOverwriteReport={(overwriteReportData: OverwriteReportDataType) => overwriteReport(overwriteReportData)}
        enableSavedReport={true}
        reportCode={reportViewCode}
        handleColumnsChange={handleColumnsChange}
        handleRefetch={() => setFetchDataGrid(!fetchDataGrid)}
        getRowHeight={() => 'auto'}
        sortModel={sortModel}
        sortingMode={GridFeatureModeConstant.server}
        setColumns={setColumns}
        filtersSearch={() => setFilterSearch(!filterSearch)}
      />
    </GridContainer>
  );
};