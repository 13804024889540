import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { Update } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NotesIcon from '@mui/icons-material/Notes';
import HistoryIcon from '@mui/icons-material/History';
import FolderIcon from '@mui/icons-material/Folder';
import ExtensionIcon from '@mui/icons-material/Extension';
import Slider from 'react-slick';
import { ServiceDispatchShipmentData, ServiceObject } from '../../../@types/service.type';
import { Chip } from '../../../components/atoms/Chip';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { TypographyVariantProps } from '../../../components/atoms/Typography';
import { Button, ButtonVariantProps } from '../../../components/atoms/Button';
import { Loader, LoaderColorProps } from '../../../components/atoms/Loader';
import { SAMPLE_SERVICE, SERVICE_DISPATCH_SHIPMENT_DATA, SERVICE_EDIT_CAROUSEL_CONFIG } from '../../../constants/service.constant';
import {
  AutocompleteTagType,
  getNewTagsMapped,
  getSelectedExistingTagsMapped,
  mapSavedTags,
  Tags
} from '../../../components/organisms/Tags';
import { ServiceActionBarFragment } from './ServiceActionBarFragment';
import { ServiceCheckInFragment } from './ServiceCheckInFragment';
import { ServiceCheckOutFragment } from './ServiceCheckOutFragment';
import { ServiceSummaryFragment } from './ServiceSummaryFragment';
import { LocationMapAccordianFragment } from './LocationMapAccordianFragment';
import { ServiceHistoryFragment } from './ServiceHistoryFragment';
import { ReportedInformationFragment } from './ReportedInformationFragment';
import {
  AddTagFabButton,
  ServiceUpdateTypography,
  ServiceSummeryBox,
  Container,
  ContentBox,
  Footer,
  CarouselWrapper,
  StyledCarouselContent
} from './UpdateService.styles';
import {
  ActionPermissions,
  CustomPermission,
  Tag,
  FontSizeProps,
  ScreenSize
} from '../../../@types';
import { EntityType, ServiceEditFragment, ServiceTypeCode } from '../../../configs/enums';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { getAllTags, useAddTags } from '../../../queries/tags-query';
import { useGetServiceById } from '../../../queries/service-query';
import { isUserHasPermission } from '../../../configs/permissions';
import { ServiceAdministrationFragment } from './ServiceAdministrationFragment';
import { IS_ADMIN_PANEL_ON_TOP } from '../../../configs/envs';
import { NotFoundError } from '../../Error/NotFound';
import { ServiceLogNoteFragment } from './ServiceLogNoteFragment/ServiceLogNoteFragment';
import { useGetServiceLogsByServiceId } from '../../../queries/service-log-query';
import { HistoryDataType } from '../../../components/molecules/TrackingHistory';
import { DispatchShipmentFragment } from './ReportedInformationFragment/DispatchShipmentFragment';
import { PLATFORM_NAME } from '../../../configs/common';
import { InteractionAvailableType, InteractionCheckInData, InteractionCheckOutData } from '../../../@types/interactions.type';
import { INTERACTION_AVAILABLE_DATA, INTERACTION_CHECKIN_DATA, INTERACTION_CHECKOUT_DATA } from '../../../constants/interaction.constant';
import { Modal } from '../../../components/atoms/Modal';
import { CloseIcon } from '../../../components/atoms/CloseIcon';
import { COLORS } from '../../../configs/colors';
import { ServicePartAccordionFragment } from './ServicePartAccordionFragment';
import { useGetServiceTypesByCodeForServiceUpdate } from '../../../queries/service-type-query';

export const UpdateService: React.FC = () => {
  const { id } = useParams();
  const serviceId = id ? parseInt(id) : -1;
  const isMobileView = useMediaQuery(ScreenSize.MOBILE);

  const [open, setOpen] = useState(false);
  const [isCheckIn, setIsCheckIn] = useState(false);
  const [isCheckOut, setIsCheckOut] = useState(false);
  const [data, setData] = useState<ServiceObject>(SAMPLE_SERVICE);
  const [selectedTags, setSelectedTags] = useState<(AutocompleteTagType | string)[]>([]);
  const [newTags, setNewTags] = useState<Tag[]>([]);
  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [permissions, setPermissions] = useState<CustomPermission[] | null>(null);
  const [adminPanelOnTop, setAdminPanelOnTop] = useState(false);
  const [serviceLogData, setServiceLogData] = useState<HistoryDataType[]>([]);
  const [dispatchData, setDispatchData] = useState<ServiceDispatchShipmentData>(SERVICE_DISPATCH_SHIPMENT_DATA);
  const [interactionsAvailable, setInteractionsAvailable] = useState<InteractionAvailableType>(INTERACTION_AVAILABLE_DATA);
  const [checkInData, setCheckInData] = useState<InteractionCheckInData>(INTERACTION_CHECKIN_DATA);
  const [checkOutData, setCheckOutData] = useState<InteractionCheckOutData>(INTERACTION_CHECKOUT_DATA);
  const [isMobile, setIsMobile] = useState(false);
  const [currentMobileTab, setCurrentMobileTab] = useState(isMobileView ? ServiceEditFragment.Location : '');
  const [isSerialNumberAtAnyLocation, setIsSerialNumberAtAnyLocation] = useState<boolean>(false);
  const [verticalPosition, setVerticalPosition] = useState(0);

  const getServiceById = useGetServiceById(serviceId);
  const userTagsQuery = getAllTags(EntityType.TYPE_SERVICES);
  const updateTagsQuery = useAddTags();
  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const getServiceLogsByServiceIdQuery = useGetServiceLogsByServiceId(serviceId);
  const getServiceType = useGetServiceTypesByCodeForServiceUpdate(data.serviceTypeCode);

  useEffect(() => {
    setIsMobile(isMobileView);
  }, [isMobileView]);

  useEffect(() => {
    if (getServiceLogsByServiceIdQuery.data) {
      const historyLogItems: HistoryDataType[] = [];
      getServiceLogsByServiceIdQuery?.data?.filter((filterdata) => !filterdata.isHidden).map((item) => {
        const isEditableInteraction = item.data?.interactionForms ? item.data?.interactionForms.isEditable : false;
        const isDownloadableInteraction = item.data?.interactionForms ? item.data?.interactionForms.isDownloadable : false;
        const historyLog = {
          id: item.id,
          time: item.time,
          user: item.userName,
          status: item.serviceStatusName,
          notes: item.notes,
          logTypeName: item.logTypeName,
          logTypeCode: item.logTypeCode,
          isEditableInteraction,
          isDownloadableInteraction,
          xmlFileKey: item?.xmlFileKey
        };
        historyLogItems.push(historyLog);
      });
      if(isMobile){
        historyLogItems.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());
      } else{
        historyLogItems.sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime());
      }
      setServiceLogData(historyLogItems);
    }
  }, [getServiceLogsByServiceIdQuery.data]);

  useEffect(() => {
    const isAdminOnTop = localStorage.getItem(IS_ADMIN_PANEL_ON_TOP);
    isAdminOnTop && setAdminPanelOnTop(JSON.parse(isAdminOnTop));
  }, []);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    if (getServiceById.data) {
      setData(getServiceById.data);
      setSelectedTags(mapSavedTags(getServiceById.data.tags));

      document.title = `Service: ${getServiceById.data.id} | Services | ${PLATFORM_NAME}`;
    }
  }, [getServiceById.data]);

  useEffect(() => {
    if (userTagsQuery.data) {
      setAllTags(userTagsQuery.data);
    }
  }, [userTagsQuery.data]);

  useEffect(() => {
    if (getServiceType.data) {
      const target = getServiceType.data.targetOptions.find((target) => target.code === 'serial-any' && target.isDefault);
      target && setIsSerialNumberAtAnyLocation(true);
    }
  }, [getServiceType.data, data]);

  useEffect(() => {
    if (currentMobileTab === ServiceEditFragment.AddNotes || currentMobileTab === ServiceEditFragment.Administration) {
      setVerticalPosition(window.scrollY);
    }
    if (currentMobileTab === ServiceEditFragment.History) {
      setTimeout(() => {
        window.scrollTo(0,verticalPosition);
      }, 5);
    }
  }, [currentMobileTab]);

  const handleTagBoxOpen = () => setOpen(true);
  const handleTagBoxClose = () => setOpen(false);

  const onChangeSelectedTags = (value: (string | AutocompleteTagType)[]) => {
    setSelectedTags(value);
  };

  const onChangeNewTags = (value: Tag[]) => {
    setNewTags(value);
  };

  const onChangeTags = async () => {
    const existingTags = getSelectedExistingTagsMapped(selectedTags);
    const freshTags = getNewTagsMapped(newTags);

    await updateTagsQuery.mutateAsync({
      entityTypeId: EntityType.TYPE_SERVICES,
      entityId: data.id,
      freshTags,
      existingTags
    });
    getServiceById.refetch();
    setOpen(false);
  };

  const handleTabChange = (currentTabId: number) => {
    setCurrentMobileTab(CarouselTabs[currentTabId].title);
  };

  const renderComponent = () => {
    return <Grid marginTop={2}>
      {isUserHasPermission(ActionPermissions.Service_Edit_Administration_Section, permissions) && (
        <ServiceAdministrationFragment
          data={data}
          adminPanelOnTop={adminPanelOnTop}
          onClick={(event) => {
            event.stopPropagation();
            setAdminPanelOnTop(!adminPanelOnTop);
            localStorage.setItem(IS_ADMIN_PANEL_ON_TOP, JSON.stringify(!adminPanelOnTop));
          }}
          expandOnMobile={!!currentMobileTab}
        />
      )}
    </Grid>;
  };

  const CarouselTabs = [
    { title: ServiceEditFragment.Location, icon: <LocationOnIcon /> },
    { title: ServiceEditFragment.ReportedInfo, icon: <InfoIcon /> },
    ...(data.reportedSerialNumber && isUserHasPermission(ActionPermissions.Service_Edit_Part_Section, permissions) ? [{ title: ServiceEditFragment.Parts, icon: <ExtensionIcon /> }] : []),
    { title: ServiceEditFragment.AddNotes, icon: <NotesIcon /> },
    { title: ServiceEditFragment.History, icon: <HistoryIcon /> },
    { title: ServiceEditFragment.Administration, icon: <FolderIcon /> }
  ];

  if (!getServiceById.data && id !== 'create') {
    return getServiceById.isLoading ? <Grid ml="570px"><Loader color={LoaderColorProps.Success} /></Grid> : <NotFoundError></NotFoundError>;
  }

  if (!getServiceLogsByServiceIdQuery.data && id !== 'create') {
    return getServiceLogsByServiceIdQuery.isLoading ? <Grid ml="570px"><Loader color={LoaderColorProps.Success} /></Grid> : <NotFoundError></NotFoundError>;
  }

  return (
    <PageContainer>
      <ServiceUpdateTypography variant={TypographyVariantProps.H5} fontWeight={600}>
        <Grid container spacing={2}>
          <Grid item>
            Service #{data.id} - {data.mainLocation}
          </Grid>
          {data.tags.map((tag: Tag) =>
            <Grid item key={tag.id}>
              <Chip label={tag.text} backgroundColor={tag.colour} textColor="#ffffff" onClick={handleTagBoxOpen} />&nbsp;
            </Grid>
          )}
          {data.tags && data.tags.length == 0 &&
            <Grid marginTop={'4px'}>
              <AddTagFabButton onClick={handleTagBoxOpen} sx={{ zIndex: 1 }}>
                <AddIcon fontSize={FontSizeProps.Large} />
              </AddTagFabButton>
            </Grid>
          }
          {isUserHasPermission(ActionPermissions.Service_Edit_Service_Parameters_General, permissions) && (
            <Modal
              open={open}
              onClose={handleTagBoxClose}
              aria-labelledby="tag-modal-title"
              aria-describedby="tag-modal-description"
            >
              <Container>
                <CloseIcon handleClose={handleTagBoxClose} />
                <ContentBox>
                  <Tags
                    selectedTags={selectedTags}
                    newTags={newTags}
                    setSeletedTags={onChangeSelectedTags}
                    setNewTags={onChangeNewTags}
                    allTags={allTags}
                  />
                </ContentBox>
                <Footer direction="row-reverse" spacing={1}>
                  <Button variant={ButtonVariantProps.Primary} startIcon={<Update />} onClick={onChangeTags}>
                    Save
                  </Button>
                </Footer>
              </Container>
            </Modal>
          )}
        </Grid>
      </ServiceUpdateTypography>
      <ServiceSummeryBox>
        {isCheckIn &&
          <ServiceCheckInFragment
            data={data}
            onClose={() => {
              setIsCheckIn(false);
              setInteractionsAvailable(INTERACTION_AVAILABLE_DATA);
            }}
            interactionsAvailable={interactionsAvailable.interactions}
            onNoteAndServiceIdsUpdate={(travelStart, noteText, selectedServiceIds) => setCheckInData({ travelStart, noteText, selectedServiceIds })}
          />
        }
        {isCheckOut &&
          <ServiceCheckOutFragment
            data={data}
            onClose={() => {
              setIsCheckOut(false);
              setInteractionsAvailable(INTERACTION_AVAILABLE_DATA);
            }}
            interactionsAvailable={interactionsAvailable.interactions}
            onNoteStatusAndTimeUpdate={(serviceStatus, noteText, offSiteTime) => setCheckOutData({ serviceStatus, noteText, offSiteTime })}
          />
        }
        {interactionsAvailable.load && !interactionsAvailable.interactions && checkOutData.serviceStatus === '' && (isCheckIn || isCheckOut) ||
          <ServiceActionBarFragment
            data={data}
            onCheckIn={() => setIsCheckIn(true)}
            onCheckOut={() => setIsCheckOut(true)}
            onChangeInterceptedFormsObject={(interactionsAvailable) => setInteractionsAvailable({ load: true, interactions: interactionsAvailable })}
            closeCheckInCheckOut={() => {
              setIsCheckIn(false);
              setIsCheckOut(false);
              setCheckInData(INTERACTION_CHECKIN_DATA);
              setInteractionsAvailable(INTERACTION_AVAILABLE_DATA);
            }}
            checkInData={checkInData}
            checkOutData={checkOutData}
            isCheckIn={isCheckIn}
            isCheckOut={isCheckOut}
          />
        }
        <ServiceSummaryFragment data={data} />
      </ServiceSummeryBox>
      {isMobile &&
        <Slider
          {...SERVICE_EDIT_CAROUSEL_CONFIG}
          afterChange={(currentTabId: number) => handleTabChange(currentTabId)}
        >
          {CarouselTabs.map((item, index) => (
            <Grid key={index} padding={1} justifyContent="center">
              <CarouselWrapper elevation={3}>
                <StyledCarouselContent>
                  <Stack>{item.icon}</Stack>
                  <Stack ml={2}><Typography variant={TypographyVariantProps.Subtitle1} color={COLORS.Purple}>{item.title}</Typography></Stack>
                </StyledCarouselContent>
              </CarouselWrapper>
            </Grid>
          ))}
        </Slider>
      }
      {data.reportedSerialNumber && isUserHasPermission(ActionPermissions.Service_Edit_Part_Section, permissions) && isSerialNumberAtAnyLocation && (!isMobile || currentMobileTab === ServiceEditFragment.Parts) && 
      <Grid marginTop={2}>
        <ServicePartAccordionFragment service={data} partId={data.reportedSerialNumberPartId} expand={!!currentMobileTab || data.serviceTypeCode === ServiceTypeCode.Repair} />
      </Grid>}
      {adminPanelOnTop && (!isMobile || currentMobileTab === ServiceEditFragment.Administration) && renderComponent()}
      {(!isMobile || currentMobileTab === ServiceEditFragment.Location) &&
        <Grid marginTop={2}>
          <LocationMapAccordianFragment data={data} isService={true} expandOnMobile={!!currentMobileTab} />
        </Grid>
      }
      {(!isMobile || currentMobileTab === ServiceEditFragment.ReportedInfo) &&
        <Grid container direction="row" marginTop={2}>
          <Grid item width={dispatchData.exited ? '100%' : '50%'}>
            <ReportedInformationFragment data={data} onDispatch={(dispatchData) => setDispatchData(dispatchData)} expandOnMobile={!!currentMobileTab} />
          </Grid>
          <Grid item width="50%">
            <DispatchShipmentFragment data={data} dispatchData={dispatchData} onDispatch={(dispatchData) => setDispatchData(dispatchData)} />
          </Grid>
        </Grid>
      }
      {data.reportedSerialNumber && isUserHasPermission(ActionPermissions.Service_Edit_Part_Section, permissions) && !isSerialNumberAtAnyLocation && (!isMobile || currentMobileTab === ServiceEditFragment.Parts) && 
      <Grid marginTop={2}>
        <ServicePartAccordionFragment service={data} partId={data.reportedSerialNumberPartId} expand={!!currentMobileTab || data.serviceTypeCode === ServiceTypeCode.Repair} />
      </Grid>
      }
      {isUserHasPermission(ActionPermissions.Service_Edit_Add_Notes, permissions) && (!isMobile || currentMobileTab === ServiceEditFragment.AddNotes) &&
        <Grid marginTop={2}>
          <ServiceLogNoteFragment data={data} />
        </Grid>
      }
      {(!isMobile || currentMobileTab === ServiceEditFragment.History) &&
        <Grid marginTop={2}>
          <ServiceHistoryFragment serviceLogData={serviceLogData} permissions={permissions} serviceId={data.id} service={data} />
        </Grid>
      }
      {adminPanelOnTop || (!isMobile || currentMobileTab === ServiceEditFragment.Administration) && renderComponent()}
    </PageContainer>
  );
};